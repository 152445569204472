define("scientia-resourcebooker/pods/manage-resources/user-group/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/permissions-summary"], ["model", ["subexpr", "@mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [7, 14], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [8, 14], [8, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [9, 27], [9, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 22], [9, 62]]], 0, 0], null], [], ["loc", [null, [9, 14], [9, 68]]], 0, 0]], ["loc", [null, [5, 11], [9, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [10, 34], [10, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 29], [10, 69]]], 0, 0], null], [], ["loc", [null, [10, 21], [10, 75]]], 0, 0]], ["loc", [null, [4, 9], [10, 76]]], 0, 0]], ["loc", [null, [2, 2], [11, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 10
                },
                "end": {
                  "line": 28,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["type", "danger", "icon", "icons/trash.svg", "text", ["subexpr", "t", ["pods.manage_resources.user_group.edit.delete_user_group"], [], ["loc", [null, [26, 17], [26, 78]]], 0, 0], "action", ["subexpr", "action", ["deleteModel"], [], ["loc", [null, [27, 19], [27, 41]]], 0, 0]], ["loc", [null, [23, 12], [27, 43]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 6
              },
              "end": {
                "line": 30,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "can", ["delete user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [22, 47], [22, 62]]], 0, 0, 0, 0]], ["loc", [null, [22, 16], [22, 63]]], 0, 0]], [], 0, null, ["loc", [null, [22, 10], [28, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [19, 6], [30, 34]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 10
                },
                "end": {
                  "line": 40,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [39, 12], [39, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.description"], [], ["loc", [null, [39, 73], [39, 130]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 12
                  },
                  "end": {
                    "line": 45,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/edit.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [44, 14], [44, 71]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.claims"], [], ["loc", [null, [44, 71], [44, 123]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 10
                },
                "end": {
                  "line": 46,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [43, 12], [45, 23]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 12
                  },
                  "end": {
                    "line": 51,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [50, 14], [50, 72]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.permissions"], [], ["loc", [null, [50, 72], [50, 129]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 10
                },
                "end": {
                  "line": 52,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [49, 12], [51, 23]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 8
              },
              "end": {
                "line": 54,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [38, 10], [40, 21]]]], ["block", "if", [["subexpr", "can", ["edit-claims user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [42, 52], [42, 67]]], 0, 0, 0, 0]], ["loc", [null, [42, 16], [42, 68]]], 0, 0]], [], 1, null, ["loc", [null, [42, 10], [46, 17]]]], ["block", "if", [["subexpr", "can", ["edit-permission-tab user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [48, 60], [48, 75]]], 0, 0, 0, 0]], ["loc", [null, [48, 16], [48, 76]]], 0, 0]], [], 2, null, ["loc", [null, [48, 10], [52, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 8
              },
              "end": {
                "line": 66,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/user-group-form", [], ["localePrefix", "components.manage_resources.user_group_edit", "name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [59, 17], [59, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "isDeprecatedRole", ["subexpr", "@mut", [["get", "isResourceOwnerGroup", ["loc", [null, [60, 29], [60, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystemGroup", ["subexpr", "@mut", [["get", "model.userGroup.isSystemGroup", ["loc", [null, [61, 26], [61, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoadingPermissionSummary", ["subexpr", "@mut", [["get", "fetchPermissionSummary.isRunning", ["loc", [null, [62, 39], [62, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "showPermissionSummaryLink", true, "showResourcePermissionSummary", ["subexpr", "action", ["showResourcePermissionSummary"], [], ["loc", [null, [64, 42], [64, 82]]], 0, 0]], ["loc", [null, [57, 10], [65, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 10
                },
                "end": {
                  "line": 76,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/claims-form", [], ["localePrefix", "components.manage_resources.claims_form_edit", "claims", ["subexpr", "@mut", [["get", "model.userGroup.claims", ["loc", [null, [72, 21], [72, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [73, 19], [73, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "isDeprecatedRole", ["subexpr", "@mut", [["get", "isResourceOwnerGroup", ["loc", [null, [74, 31], [74, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [70, 12], [75, 14]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 8
              },
              "end": {
                "line": 77,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-panel", [], ["class", "at-claimsForm"], 0, null, ["loc", [null, [69, 10], [76, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 10
                },
                "end": {
                  "line": 83,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1, "class", "manageResources-itemForm-title at-permissionsForm");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["pods.manage_resources.resource_type.edit.permissions_title"], ["name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [81, 144], [81, 164]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [81, 74], [81, 166]]], 0, 0], ["inline", "manage-resources/permissions-form", [], ["preventClaimToggler", true, "permissions", ["subexpr", "@mut", [["get", "permissions", ["loc", [null, [82, 85], [82, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "permissionsTypes", ["subexpr", "@mut", [["get", "permissionsTypes", ["loc", [null, [82, 114], [82, 130]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [82, 12], [82, 132]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 79,
                "column": 8
              },
              "end": {
                "line": 84,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-panel", [], ["class", "at-permissionsForm"], 0, null, ["loc", [null, [80, 10], [83, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 6
            },
            "end": {
              "line": 86,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [36, 8], [54, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [56, 8], [66, 25]]]], ["block", "if", [["subexpr", "can", ["edit-claims user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [68, 50], [68, 65]]], 0, 0, 0, 0]], ["loc", [null, [68, 14], [68, 66]]], 0, 0]], [], 2, null, ["loc", [null, [68, 8], [77, 15]]]], ["block", "if", [["subexpr", "can", ["edit-permission-tab user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [79, 59], [79, 74]]], 0, 0, 0, 0]], ["loc", [null, [79, 15], [79, 75]]], 0, 0]], [], 3, null, ["loc", [null, [79, 8], [84, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(element2, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [1, 6], [1, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [15, 39], [15, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 0], [15, 53]]], 0, 0], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.user_group.edit.title"], ["name", ["get", "model.userGroup.name", ["loc", [null, [18, 80], [18, 100]]], 0, 0, 0, 0]], ["loc", [null, [18, 26], [18, 101]]], 0, 0]], 1, null, ["loc", [null, [18, 4], [31, 24]]]], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [33, 24], [33, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [33, 6], [33, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [35, 59], [35, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [35, 6], [86, 18]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [88, 49], [88, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["onCancel"], [], ["loc", [null, [88, 71], [88, 90]]], 0, 0], "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [88, 98], [88, 113]]], 0, 0]], ["loc", [null, [88, 6], [88, 115]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});