define('scientia-resourcebooker/mixins/resource-filters', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    customEvents: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    customFiltersSelected: [],
    customFiltersAreDebouncing: false,
    // 1: Single Booking, 2: Recurring booking
    bookingMode: 1,
    bookingModeIsRecurring: _ember['default'].computed.equal('bookingMode', 2),

    recurrenceErrors: [],
    recurrenceErrorsServer: null,

    calculateHideResourceGroupCount: function calculateHideResourceGroupCount(recurrencePattern) {
      var properties = _ember['default'].getProperties(this, ['availableNow', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'query']);

      // Should hide count if is recurringMode or any of the unavailability properties above are set
      return !!recurrencePattern || !!Object.keys(properties).find(function (k) {
        return !!properties[k];
      }) || this.get('customFiltersSelected.length');
    },

    getResourceFilters: function getResourceFilters() {
      var properties = _ember['default'].getProperties(this, ['availableNow', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'resourceGroupId', 'query']);

      return this.hasOneFilterAttribute(properties) ? properties : {};
    },

    hasOneFilterAttribute: function hasOneFilterAttribute(_ref) {
      var availableNow = _ref.availableNow;
      var firstDay = _ref.firstDay;
      var lastDay = _ref.lastDay;
      var fromTime = _ref.fromTime;
      var toTime = _ref.toTime;
      var duration = _ref.duration;
      var resourceGroupId = _ref.resourceGroupId;
      var query = _ref.query;

      return this.hasOneTimeFilterAttribute({ availableNow: availableNow, firstDay: firstDay, lastDay: lastDay, fromTime: fromTime, toTime: toTime, duration: duration, query: query }) || this.hasOneSearchFilterAttribute({ resourceGroupId: resourceGroupId });
    },

    hasOneTimeFilterAttribute: function hasOneTimeFilterAttribute(_ref2) {
      var availableNow = _ref2.availableNow;
      var firstDay = _ref2.firstDay;
      var lastDay = _ref2.lastDay;
      var fromTime = _ref2.fromTime;
      var toTime = _ref2.toTime;
      var duration = _ref2.duration;
      var query = _ref2.query;

      return !!(availableNow || firstDay || lastDay || fromTime || toTime || duration || query);
    },

    hasOneSearchFilterAttribute: function hasOneSearchFilterAttribute(_ref3) {
      var resourceGroupId = _ref3.resourceGroupId;

      return !!resourceGroupId;
    },

    actions: {

      bookingModeChanged: function bookingModeChanged(value) {
        this.set('bookingMode', parseInt(value));

        if (parseInt(value) === 1) {
          this.send('invalidateModel');
          this.send('resetFilters', false);
        }
      },

      searchRecurring: function searchRecurring(isLimitedRecurrence) {

        this.set('recurrenceErrors', []);
        this.set('recurrenceErrorsServer', null);
        var errors = this.get('recurrenceProcessor').validate(this.get('recurrence'), isLimitedRecurrence);
        if (!_ember['default'].isEmpty(errors)) {
          this.set('recurrenceErrors', errors);
        } else {
          // Make sure we clear all the single booking availability filters
          this.send('resetFilters', false);
          this.send('invalidateModel');
        }
      },

      // Action called when a custom Filter (LIST) has been changed
      customFilterListChanged: function customFilterListChanged(propertyId, listValue, propertyName) {
        var type = 'ms';
        var customFiltersSelected = this.get('customFiltersSelected');
        var filterExists = customFiltersSelected.findBy('identity', propertyId);
        if (!filterExists) {
          var newFilter = _ember['default'].Object.create({ identity: propertyId, value: [listValue], name: propertyName, type: type });
          customFiltersSelected.pushObject(newFilter);
          this.logFilter({ name: propertyName, value: listValue, type: type });
        } else {
          var filterValues = filterExists.get('value');
          var valueIndexOf = filterValues.indexOf(listValue);
          if (valueIndexOf === -1) {
            filterValues.push(listValue);
            this.logFilter({ name: propertyName, value: listValue, type: type });
          } else {
            filterValues.removeAt(valueIndexOf);
            if (_ember['default'].isEmpty(filterValues)) {
              customFiltersSelected.removeObject(filterExists);
            }
          }
        }
        this.updateCustomFilters(true);
      },

      customFilterTextChangedDebounced: function customFilterTextChangedDebounced(propertyId, propertyValue, propertyName) {
        _ember['default'].run.debounce(this, 'send', 'customFilterTextChanged', propertyId, propertyValue, propertyName, 1000);
      },

      // Action called when a custom Filter (TEXT) has been changed
      customFilterTextChanged: function customFilterTextChanged(propertyId, propertyValue, propertyName) {
        var type = 't';
        var customFiltersSelected = this.get('customFiltersSelected');
        var currentProperty = customFiltersSelected.findBy('identity', propertyId);

        // If the filter is not set and there is a value just set new filter
        if (_ember['default'].isEmpty(currentProperty) && !_ember['default'].isEmpty(propertyValue)) {
          var newFilter = _ember['default'].Object.create({ identity: propertyId, value: propertyValue, name: propertyName, type: type });
          customFiltersSelected.pushObject(newFilter);

          this.logFilter({ name: propertyName, value: propertyValue, type: type });
          return this.updateCustomFilters(true); // We want to show debounce
        }

        //If the property is empty remove the filter else just update it
        if (!_ember['default'].isEmpty(propertyValue)) {
          currentProperty.set('value', propertyValue);
          this.logFilter({ name: propertyName, value: propertyValue, type: type });
          return this.updateCustomFilters(true); // We want to show debounce here as well
        } else {
            customFiltersSelected.removeObject(currentProperty);
            return this.updateCustomFilters(); // No debounce as we are removing the property
          }
      },

      // Action called when a custom Filter (Radio) has been changed
      customFilterRadioChanged: function customFilterRadioChanged(propertyId, propertyValue, propertyName) {
        var type = 'ss';
        var customFiltersSelected = this.get('customFiltersSelected');
        var currentProperty = customFiltersSelected.findBy('identity', propertyId);

        // If the filter is not set just set it
        if (_ember['default'].isEmpty(currentProperty)) {
          var newFilter = _ember['default'].Object.create({ identity: propertyId, value: propertyValue, name: propertyName, type: type });
          customFiltersSelected.pushObject(newFilter);

          this.logFilter({ name: propertyName, value: propertyValue, type: type });
          this.updateCustomFilters(true);
          return;
        }

        if (propertyValue) {
          currentProperty.set('value', propertyValue);
        } else {
          customFiltersSelected.removeObject(currentProperty);
        }

        this.logFilter({ name: propertyName, value: propertyValue, type: type });
        this.updateCustomFilters(true);
      },

      // Action called when a custom Filter (YES/No Single Checkbox) has been changed
      customFilterSingleCheckboxChanged: function customFilterSingleCheckboxChanged(propertyId, propertyValue, propertyName) {
        var type = 'yn';
        var customFiltersSelected = this.get('customFiltersSelected');
        var currentProperty = customFiltersSelected.findBy('identity', propertyId);

        this.logFilter({ name: propertyName, value: !!propertyValue, type: type });

        // If the filter is not set just set it
        if (_ember['default'].isEmpty(currentProperty)) {
          var newFilter = _ember['default'].Object.create({ identity: propertyId, value: propertyValue, name: propertyName, type: type });
          customFiltersSelected.pushObject(newFilter);
          this.updateCustomFilters(true);
          return;
        }

        // If the checkbox value is false remove the property else add it
        if (!propertyValue) {
          customFiltersSelected.removeObject(currentProperty);
        } else {
          currentProperty.set('value', true);
        }

        this.updateCustomFilters(true);
      },

      customFilterNumberChanged: function customFilterNumberChanged(propertyId, propVal, propName) {
        var type = 'nr';
        var customFiltersSelected = this.get('customFiltersSelected');
        var currentProperty = customFiltersSelected.findBy('identity', propertyId);

        // If this filter is not set yet and a propVal has been set add it to the filters
        if (_ember['default'].isEmpty(currentProperty) && !_ember['default'].isEmpty(propVal)) {
          var newFilter = _ember['default'].Object.create({ identity: propertyId, value: propVal, name: propName, type: type });
          customFiltersSelected.pushObject(newFilter);

          var isMin = ('min' in propVal);
          var logData = { name: propName, value: isMin ? propVal.min : propVal.max, type: type };
          if (isMin) logData.min = true;else logData.max = true;
          this.logFilter(logData);

          return this.updateCustomFilters(true);
        }

        if (propVal && propVal.min && propVal.min !== currentProperty.value.min) {
          currentProperty.value.min = propVal.min;
          this.logFilter({ name: propName, min: true, value: propVal.min, type: type });
          return this.updateCustomFilters(true);
        }

        if (propVal && propVal.max && propVal.max !== currentProperty.value.max) {
          currentProperty.value.max = propVal.max;
          this.logFilter({ name: propName, max: true, value: propVal.max, type: type });
          return this.updateCustomFilters(true);
        }

        if (propVal && !propVal.min) delete currentProperty.value.min;
        if (propVal && !propVal.max) delete currentProperty.value.max;

        if (!propVal || !propVal.min && !propVal.max) {
          customFiltersSelected.removeObject(currentProperty);
          return this.updateCustomFilters(true);
        }

        this.updateCustomFilters();
      },

      removeDefaultFilter: function removeDefaultFilter(filter) {
        var id = filter.id;
        var resetValue = null;

        if (id === 'availableNow' || id === 'spanMultipleDays') {
          resetValue = false;
        }
        if (id === 'fromTime' || id === 'toTime') {
          resetValue = '';
        }
        this.set(id, resetValue);

        // Force removing selected class from kalendae when clearing firstDay
        if (id === 'firstDay') {
          _ember['default'].$('.k-selected').removeClass('k-selected');
        }

        if (id === 'spanMultipleDays') {
          this.setProperties({
            fromTime: '',
            toTime: '',
            firstDay: null,
            lastDay: null
          });
        }

        // this.send('reloadModelWithFilters');
      },

      // Action ran when we are removing a custom filter
      removeCustomFilter: function removeCustomFilter(filterToRemove) {
        var filterId = filterToRemove.identity;
        var filters = this.get('customFiltersSelected');
        var currentFilter = filters.findBy('identity', filterId);

        var filterValues = currentFilter.value;

        // If the filter value is an array
        if (_ember['default'].isArray(filterValues)) {

          // If there is only one value remove the filter else remove the correct index
          if (filterValues.length === 1) {
            filters.removeObject(currentFilter);
          } else {
            var valueIndex = filterValues.indexOf(filterToRemove.value);
            filterValues.removeAt(valueIndex);
          }

          // If thefilter value is an object just remove the current key
        } else if (typeof filterValues === 'object') {
            delete filterValues[filterToRemove.key];

            // and if after removing the key the filter is empty, remove it
            if (Object.keys(filterValues).length === 0) {
              filters.removeObject(currentFilter);
            }

            // If the filter value is a single value just remove the filter
          } else {
              filters.removeObject(currentFilter);
            }

        // Workaround for unchecking a checkbox on the sidebar filters in case the current filter being removed is a checkbox
        var isCheckbox = _ember['default'].$('.resourceFilter-type-list[data-property-identity="' + filterId + '"] input[type=checkbox]');
        if (isCheckbox.length) {
          var checkbox = _ember['default'].$('.resourceFilter-type-list[data-property-identity="' + filterId + '"] label[data-property-value="' + filterToRemove.value + '"] input[type=checkbox]');
          _ember['default'].$(checkbox).prop('checked', false);
        }

        this._updateCustomFilters();
        this.get('customEvents').triggerRemoveCustomFilter(filterToRemove);
      },

      resetFilters: function resetFilters() {
        var includingCustomFilters = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.set('firstDay', null);
        this.set('lastDay', null);
        this.set('fromTime', '');
        this.set('toTime', '');
        this.set('availableNow', false);
        this.set('spanMultipleDays', false);
        this.set('duration', null);
        this.set('loadedIds', []);
        this.set('model.resources', []);
        if (includingCustomFilters) {
          this.set('customFiltersSelected', []);
          this._updateCustomFilters();
          this.get('customEvents').triggerResetFilters();
        }
      }
    },

    logFilter: function logFilter(filterData) {

      // Don't log if level 1 or lower
      if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) <= 1) {
        return;
      }

      filterData.bookingType = this.get('model.bookingType.name');
      filterData.bookingTypeId = this.get('model.bookingType.id');

      this.get('appInsights').trackEvent('resource-filter', filterData);
    },

    // Make sure we debounce the query so we don't hit the API all the time
    // 6-Nov-2019: We have disabled debounce due to performance improvements in the past
    updateCustomFilters: function updateCustomFilters(showDebouncing) {

      var debounceDisabled = true;
      if (!debounceDisabled && showDebouncing) {
        this.set('customFiltersAreDebouncing', false);
        _ember['default'].run.next(this, 'set', 'customFiltersAreDebouncing', true);
      }

      if (debounceDisabled) {
        this._updateCustomFilters();
      } else {
        _ember['default'].run.debounce(this, '_updateCustomFilters', 1000);
      }
    },

    // This is the actual function that gets called from the above method (debounced)
    _updateCustomFilters: function _updateCustomFilters() {
      this.set('loadedIds', []);
      this.set('model.resources', []);

      this.get('filterResourcesAndGroups').perform();
    }

  });
});