define('scientia-resourcebooker/services/current-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    systemSettings: _ember['default'].inject.service(),

    userGroups: _ember['default'].computed.alias('user.userGroups'),

    user: null,
    userPermissions: null,
    hasLoadedPermissions: false,

    getUser: function getUser() {
      return this.get('user');
    },

    setUser: function setUser(user) {
      this.set('user', user);
    },

    // Sets async permissions
    setPermissions: function setPermissions(perms) {
      var permissions = _ember['default'].Object.create();
      permissions.setProperties({
        isBookingsReporter: perms.isBookingsReporter,
        isResourceOwner: perms.isResourceOwner,
        isTemplateOwner: perms.isTemplateOwner,
        isUserGroupOwner: perms.isUserGroupOwner,
        isAuditReporter: perms.isAuditReporter,
        isConcurrencyManager: perms.concurrencyManager,
        canImportResourceCustomFields: perms.importExportPermissions.resourceCustomFields,
        canImportResourcePermissions: perms.importExportPermissions.resourcePermissions,
        canImportUserGroups: perms.importExportPermissions.userGroups
      });

      this.setProperties({
        userPermissions: permissions,
        hasLoadedPermissions: true
      });
    },

    // Old roles property that used to contain all perms, but now contains only isAdmin/institutionAdmin
    hasRole: function hasRole(role) {
      return this.get('user.roles').indexOf(role) !== -1;
    },

    // This and isAdmin are now the only permissions that are returned in the user.roles[] array
    isInstitutionAdmin: function isInstitutionAdmin() {
      return this.hasRole('institution admin');
    },

    isAdmin: function isAdmin() {
      return this.hasRole('admin') || this.isInstitutionAdmin();
    },

    // All below permissions are set on the roles[]
    isBookingsReporter: function isBookingsReporter() {
      return this.get('userPermissions.isBookingsReporter') || this.isInstitutionAdmin();
    },

    isAuditReporter: function isAuditReporter() {
      return this.get('userPermissions.isAuditReporter') || this.isInstitutionAdmin();
    },

    isResourceOwner: function isResourceOwner() {
      return this.get('userPermissions.isResourceOwner') || this.isInstitutionAdmin();
    },

    isTemplateOwner: function isTemplateOwner() {
      return this.get('userPermissions.isTemplateOwner') || this.isInstitutionAdmin();
    },

    isUserGroupOwner: function isUserGroupOwner() {
      return this.get('userPermissions.isUserGroupOwner') || this.isInstitutionAdmin();
    },

    isConcurrencyManager: function isConcurrencyManager() {
      return this.get('userPermissions.isConcurrencyManager') || this.isInstitutionAdmin();
    },

    canImportExportResourcePermissions: function canImportExportResourcePermissions() {
      return this.get('userPermissions.canImportResourcePermissions') || this.isInstitutionAdmin();
    },

    canImportExportResourceCustomFields: function canImportExportResourceCustomFields() {
      return this.get('userPermissions.canImportResourceCustomFields') || this.isInstitutionAdmin();
    },

    canImportExportUserGroups: function canImportExportUserGroups() {
      return this.get('userPermissions.canImportUserGroups') || this.isInstitutionAdmin();
    },

    canViewOwnPermissionSummary: _ember['default'].computed.alias('user.canViewMyPermissions'),

    getInstitutionId: function getInstitutionId() {
      return this.get('user.institutionId');
    },

    getNameIdentifier: function getNameIdentifier() {
      return this.get('user.nameIdentifier');
    }
  });
});