define('scientia-resourcebooker/services/locale-processor', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Service.extend({
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    configFetcher: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    profileProcessor: _ember['default'].inject.service(),
    canShowCalendar: true,
    getLocale: function getLocale() {
      return localStorage.getItem('scientia-rb-language') || this.get('configFetcher.language');
    },

    setLocale: function setLocale(locale, userAction) {

      var translations = getOwner(this)._lookupFactory('locale:' + locale + '/translations');
      if (translations) {

        this.get('i18n').set('locale', locale);

        var customTranslations = this.get('configFetcher.translations');
        this.addCustomTranslations(locale, customTranslations);

        moment.locale(locale);
        localStorage.setItem('scientia-rb-language', locale);
        this.saveLocaleInStorage();

        this.set('canShowCalendar', false);
        _ember['default'].run.later(this, 'set', 'canShowCalendar', true, 200);
        this.get('customEvents').triggerLocaleChanged();

        if (userAction) {
          this.get('appInsights').trackEvent('language-change', { language: locale });
          this.get('profileProcessor').setLanguage(locale);
          this.get('profileProcessor').save();
        }
      } else {
        this.setLocale(this.get('configFetcher.language') || 'en');
      }

      var langAttr = locale === 'zh-cn' ? 'zh' : locale;
      document.querySelector('html').setAttribute('lang', langAttr);
    },

    saveLocaleInStorage: function saveLocaleInStorage() {
      window.translations = this.get('i18n._locale.translations');
    },

    initLocale: function initLocale() {
      var locale = this.calculateLocale();
      this.get('i18n').set('locale', locale);

      var customTranslations = this.get('configFetcher.translations');
      this.addCustomTranslations(locale, customTranslations);

      moment.locale(locale);
      this.saveLocaleInStorage();
      // window.translations = getOwner(this)._lookupFactory(`locale:${locale}/translations`) || {};

      document.querySelector('html').setAttribute('lang', locale);
    },

    loadUserLanguage: function loadUserLanguage(language) {
      this.setLocale(language);
    },

    calculateLocale: function calculateLocale() {
      return this.getLocale() || 'en';
    },

    addCustomTranslations: function addCustomTranslations(locale, translations) {
      var i18n = this.get('i18n');

      try {
        var _ret = (function () {

          var json = JSON.parse(translations);
          if (locale in json) {
            Object.keys(json[locale]).forEach(function (key) {
              var translation = {};
              translation[key] = json[locale][key];
              i18n.addTranslations(locale, translation);
            });
          }
          return {
            v: true
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } catch (e) {
        return false;
      }
    },

    getChronosTranslations: function getChronosTranslations() {
      return this.unflatJson(window.translations).calendar;
    },

    /* jshint ignore:start */
    unflatJson: function unflatJson(json) {
      var unflatten = {};

      var _loop = function (item) {
        var _this = undefined;
        var splittedKey = item.split('.');

        splittedKey.map(function (keysPart, i) {

          if (i === 0) {
            _this = unflatten;
          }

          if (!_this[keysPart]) {
            if (splittedKey.length === i + 1) {
              _this[keysPart] = json[item];
            } else {

              _this[keysPart] = {};
              _this = _this[keysPart];
            }
          } else {
            _this = _this[keysPart];
          }
        });
      };

      for (var item in json) {
        _loop(item);
      }

      return unflatten;
    }
    /* jshint ignore:end */
  });
});