define('scientia-resourcebooker/pods/components/validated-input/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['validated-input'],
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    inputClasses: '',
    value: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    validation: null,
    showValidations: false,
    didValidate: false,

    notValidating: computed.not('validation.isValidating').readOnly(),
    hasContent: computed.notEmpty('value').readOnly(),
    hasWarnings: computed.notEmpty('validation.warnings').readOnly(),
    isValid: computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),

    showErrorClass: computed.and('notValidating', 'showErrorMessage', /*'hasContent',*/'validation').readOnly(),
    showErrorMessage: computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    numberStep: computed('type', 'model.allowDecimal', 'model.maxDecimalPlaces', function () {
      // If not a number or does not allow decimal return 1
      if (!this.get('model.allowDecimal') || this.get('type') !== 'number') return '1';

      if (!this.get('model.maxDecimalPlaces')) return 'any';

      var step = '0.';
      var maxDecimalPlaces = parseInt(this.get('model.maxDecimalPlaces'));
      for (var i = 1; i < maxDecimalPlaces; i++) {
        step += '0';
      }

      step += '1';
      return step;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = this.get('valuePath');

      defineProperty(this, 'validation', computed.readOnly('model.validations.attrs.' + valuePath));
      defineProperty(this, 'value', computed.alias('model.' + valuePath));
    },

    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      this.set('showValidations', true);
    }
  });
});
/**
 * Copyright 2016, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */