define('scientia-resourcebooker/pods/components/manage-resources/availability-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  exports['default'] = _ember['default'].Component.extend({
    resource: null,
    busyTimes: [],

    busyPatterns: [],

    startDate: null,
    endDate: null,
    selectedTab: 0,
    disabled: false,
    selectedUserGroup: DEFAULT_ALL_GROUPS,
    isClearingUnavailability: false,
    hasPendingUnavailability: false,

    localeProcessor: _ember['default'].inject.service(),
    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    userGroups: (function () {
      return [{
        id: DEFAULT_ALL_GROUPS,
        value: DEFAULT_ALL_GROUPS,
        label: this.get('i18n').t('components.manage_resources.availability_form.user_groups.any')
      }];
    }).property(),

    selectedUserGroupObserver: (function () {
      this._refreshBusyCalendar(this.getProperties('selectedTab', 'selectedUserGroup', 'startDate', 'endDate'));
    }).observes('selectedUserGroup'),

    actions: {
      showAddUnavailabilityWeekdaySlot: function showAddUnavailabilityWeekdaySlot(index, hour) {
        if (this.get('disabled')) return false;
        this.get('showAddUnavailabilityWeekdaySlot')(index, hour, this.get('selectedUserGroup'));
      },

      showRemoveUnavailabilityWeekdaySlot: function showRemoveUnavailabilityWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('showRemoveUnavailabilityWeekdaySlot')(slot);
      },

      undoAddWeekdaySlot: function undoAddWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('undoAddWeekdaySlot')(slot, this.get('selectedUserGroup'));
      },

      undoRemoveWeekdaySlot: function undoRemoveWeekdaySlot(slot) {
        if (this.get('disabled')) return false;
        this.get('undoRemoveWeekdaySlot')(slot);
      },

      showAddUnavailabilityDateSlot: function showAddUnavailabilityDateSlot(moment) {
        if (this.get('disabled')) return false;
        this.get('showAddUnavailabilityDateSlot')(moment, this.get('selectedUserGroup'));
      },

      showRemoveUnavailabilityDateSlot: function showRemoveUnavailabilityDateSlot(event) {
        if (this.get('disabled')) return false;
        this.get('showRemoveUnavailabilityDateSlot')(event, this.get('selectedUserGroup'));
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        this.sendAction('fetchBusyTimes', startDate, endDate, this.get('selectedUserGroup'));
      },

      fetchBusyPatterns: function fetchBusyPatterns() {
        this.sendAction('fetchBusyPatterns', this.get('selectedUserGroup'));
      }
    },

    _refreshBusyCalendar: function _refreshBusyCalendar(_ref) {
      var selectedTab = _ref.selectedTab;
      var selectedUserGroup = _ref.selectedUserGroup;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;

      switch (selectedTab) {
        case 0:
          this.sendAction('fetchBusyPatterns', selectedUserGroup);
          break;
        case 1:
          this.sendAction('fetchBusyTimes', startDate, endDate, selectedUserGroup);
          break;
      }
    }
  });
});