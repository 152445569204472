define("scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 4
                },
                "end": {
                  "line": 9,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element5, 'class');
              morphs[1] = dom.createMorphAt(element5, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["badge at-manageResourcesItemsList-item-label ", ["subexpr", "if", [["get", "model.resources.length", ["loc", [null, [7, 69], [7, 91]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [7, 64], [7, 107]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.resources.length", ["loc", [null, [7, 109], [7, 135]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 9,
                    "column": 4
                  },
                  "end": {
                    "line": 11,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element4, 'class');
                morphs[1] = dom.createMorphAt(element4, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["badge at-manageResourcesItemsList-item-label ", ["subexpr", "if", [["get", "model.concurrencyGroupMembers.length", ["loc", [null, [10, 69], [10, 105]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [10, 64], [10, 121]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.concurrencyGroupMembers.length", ["loc", [null, [10, 123], [10, 163]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 11,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "modelName", ["loc", [null, [9, 18], [9, 27]]], 0, 0, 0, 0], "concurrency-configuration"], [], ["loc", [null, [9, 14], [9, 56]]], 0, 0]], [], 0, null, ["loc", [null, [9, 4], [11, 4]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "alt", "");
            dom.setAttribute(el1, "class", "manageResourcesItemsList-item-icon manageResourcesItemsList-item-icon--group");
            dom.setAttribute(el1, "src", "icons/folder.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "modelName", ["loc", [null, [6, 14], [6, 23]]], 0, 0, 0, 0], "concurrency-group"], [], ["loc", [null, [6, 10], [6, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [6, 4], [11, 11]]]], ["content", "model.name", ["loc", [null, [13, 4], [13, 18]]], 0, 0, 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "link-to", [["subexpr", "concat-strings", [["get", "routePath", ["loc", [null, [2, 29], [2, 38]]], 0, 0, 0, 0], "edit"], [], ["loc", [null, [2, 13], [2, 46]]], 0, 0], ["get", "model.id", ["loc", [null, [2, 47], [2, 55]]], 0, 0, 0, 0]], ["class", "manageResourcesItemsList-item-label"], 0, null, ["loc", [null, [2, 2], [15, 14]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 4
              },
              "end": {
                "line": 25,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["badge at-manageResourcesItemsList-item-label ", ["subexpr", "if", [["get", "model.resources.length", ["loc", [null, [23, 69], [23, 91]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [23, 64], [23, 107]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.resources.length", ["loc", [null, [23, 109], [23, 135]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 4
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element1, 'class');
              morphs[1] = dom.createMorphAt(element1, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["badge at-manageResourcesItemsList-item-label ", ["subexpr", "if", [["get", "model.concurrencyGroupMembers.length", ["loc", [null, [26, 69], [26, 105]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [26, 64], [26, 121]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.concurrencyGroupMembers.length", ["loc", [null, [26, 123], [26, 163]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 4
              },
              "end": {
                "line": 27,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "modelName", ["loc", [null, [25, 18], [25, 27]]], 0, 0, 0, 0], "concurrency-configuration"], [], ["loc", [null, [25, 14], [25, 56]]], 0, 0]], [], 0, null, ["loc", [null, [25, 4], [27, 4]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "manageResourcesItemsList-item-label");
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "alt", "");
          dom.setAttribute(el2, "class", "manageResourcesItemsList-item-icon manageResourcesItemsList-item-icon--group");
          dom.setAttribute(el2, "src", "icons/folder.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element3, 3, 3);
          morphs[1] = dom.createMorphAt(element3, 5, 5);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "modelName", ["loc", [null, [22, 14], [22, 23]]], 0, 0, 0, 0], "concurrency-group"], [], ["loc", [null, [22, 10], [22, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [22, 4], [27, 11]]]], ["content", "model.name", ["loc", [null, [29, 4], [29, 18]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 8
                },
                "end": {
                  "line": 44,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "sr-only");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["general.view"], [], ["loc", [null, [42, 30], [42, 50]]], 0, 0], ["inline", "inline-svg", ["icons/calendar.svg"], ["class", "manageResourcesItemsList-item-actions-icon"], ["loc", [null, [43, 10], [43, 96]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 46,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["manage-resources.resource-type.concurrency.show", ["get", "model.id", ["loc", [null, [41, 69], [41, 77]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["isConfiguration", true], ["loc", [null, [41, 78], [41, 113]]], 0, 0]], ["class", "manageResourcesItemsList-item-link"], 0, null, ["loc", [null, [41, 8], [44, 20]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 6
                },
                "end": {
                  "line": 51,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "sr-only");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["general.view"], [], ["loc", [null, [49, 30], [49, 50]]], 0, 0], ["inline", "inline-svg", ["icons/calendar.svg"], ["class", "manageResourcesItemsList-item-actions-icon"], ["loc", [null, [50, 10], [50, 96]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 6
              },
              "end": {
                "line": 53,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["manage-resources.resource-type.concurrency.show", ["get", "model.id", ["loc", [null, [48, 67], [48, 75]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["isConfiguration", false], ["loc", [null, [48, 76], [48, 112]]], 0, 0]], ["class", "manageResourcesItemsList-item-link"], 0, null, ["loc", [null, [48, 6], [51, 20]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 4
            },
            "end": {
              "line": 55,
              "column": 3
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "modelName", ["loc", [null, [39, 16], [39, 25]]], 0, 0, 0, 0], "concurrency-configuration"], [], ["loc", [null, [39, 12], [39, 54]]], 0, 0]], [], 0, 1, ["loc", [null, [39, 6], [53, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 6
              },
              "end": {
                "line": 60,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "disabled", "");
            dom.setAttribute(el1, "class", "manageResourcesItemsList-item-link");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "manageResourcesItemsList-item-actions-icon manageResourcesItemsList-item-actions-icon--calendar"], ["loc", [null, [58, 8], [58, 147]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 3
            },
            "end": {
              "line": 61,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "can", [["subexpr", "concat-strings", ["show-calendar-icon ", ["get", "modelName", ["loc", [null, [56, 55], [56, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 17], [56, 65]]], 0, 0], ["get", "model", ["loc", [null, [56, 66], [56, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 12], [56, 72]]], 0, 0]], [], 0, null, ["loc", [null, [56, 6], [60, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 6
              },
              "end": {
                "line": 68,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "sr-only");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["general.edit"], [], ["loc", [null, [66, 28], [66, 48]]], 0, 0], ["inline", "inline-svg", ["icons/pencil.svg"], ["class", "manageResourcesItemsList-item-actions-icon"], ["loc", [null, [67, 8], [67, 92]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 64,
              "column": 4
            },
            "end": {
              "line": 69,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", [["subexpr", "concat-strings", [["get", "routePath", ["loc", [null, [65, 33], [65, 42]]], 0, 0, 0, 0], "edit"], [], ["loc", [null, [65, 17], [65, 50]]], 0, 0], ["get", "model.id", ["loc", [null, [65, 51], [65, 59]]], 0, 0, 0, 0]], ["class", "manageResourcesItemsList-item-link"], 0, null, ["loc", [null, [65, 6], [68, 18]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 4
            },
            "end": {
              "line": 73,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "manageResourcesItemsList-item-link");
          dom.setAttribute(el1, "disabled", "");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/pencil.svg"], ["class", "manageResourcesItemsList-item-actions-icon"], ["loc", [null, [71, 8], [71, 92]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 77,
                "column": 6
              },
              "end": {
                "line": 82,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "manageResourcesItemsList-item-link");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "sr-only");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["element", "action", [["subexpr", "optional", [["get", "onDelete", ["loc", [null, [78, 35], [78, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 25], [78, 44]]], 0, 0], ["get", "model", ["loc", [null, [78, 45], [78, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 16], [78, 52]]], 0, 0], ["inline", "t", ["general.delete"], [], ["loc", [null, [79, 32], [79, 54]]], 0, 0], ["inline", "inline-svg", ["icons/trash.svg"], ["class", "manageResourcesItemsList-item-actions-icon manageResourcesItemsList-item-actions-icon--delete"], ["loc", [null, [80, 10], [80, 144]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 82,
                "column": 6
              },
              "end": {
                "line": 86,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "manageResourcesItemsList-item-link");
            dom.setAttribute(el1, "disabled", "");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/trash.svg"], ["class", "manageResourcesItemsList-item-actions-icon manageResourcesItemsList-item-actions-icon--delete"], ["loc", [null, [84, 10], [84, 144]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "can", [["subexpr", "concat-strings", ["delete ", ["get", "modelName", ["loc", [null, [77, 43], [77, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 17], [77, 53]]], 0, 0], ["get", "model", ["loc", [null, [77, 54], [77, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 12], [77, 60]]], 0, 0]], [], 0, 1, ["loc", [null, [77, 6], [86, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/concurrency-resources-list/item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "manageResourcesItemsList-item-actions manageResourcesItemsList-item-actions--icon");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element6, 1, 1);
        morphs[2] = dom.createMorphAt(element6, 3, 3);
        morphs[3] = dom.createMorphAt(element6, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "canEdit", ["loc", [null, [1, 6], [1, 13]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [32, 7]]]], ["block", "if", [["subexpr", "and", [["subexpr", "can", [["subexpr", "concat-strings", ["show-calendar-icon ", ["get", "modelName", ["loc", [null, [37, 58], [37, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 20], [37, 68]]], 0, 0], ["get", "model", ["loc", [null, [37, 69], [37, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 15], [37, 75]]], 0, 0], ["subexpr", "can", [["subexpr", "concat-strings", ["view-calendar ", ["get", "modelName", ["loc", [null, [37, 114], [37, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 81], [37, 124]]], 0, 0], ["get", "model", ["loc", [null, [37, 125], [37, 130]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 76], [37, 131]]], 0, 0]], [], ["loc", [null, [37, 10], [37, 132]]], 0, 0]], [], 2, 3, ["loc", [null, [37, 4], [61, 11]]]], ["block", "if", [["subexpr", "can", [["subexpr", "concat-strings", ["edit ", ["get", "modelName", ["loc", [null, [64, 39], [64, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 15], [64, 49]]], 0, 0], ["get", "model", ["loc", [null, [64, 50], [64, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [64, 10], [64, 56]]], 0, 0]], [], 4, 5, ["loc", [null, [64, 4], [73, 11]]]], ["block", "unless", [["get", "hideDeleteAction", ["loc", [null, [76, 14], [76, 30]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [76, 4], [87, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});