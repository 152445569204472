define('scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var UNAVAILABILITY_TYPES = ['resource', 'template', 'event', 'booking', 'general', 'concurrency'];

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    nestedResourceStore: _ember['default'].inject.service(),
    bookingRequestCreator: _ember['default'].inject.service(),
    bookingRequestDestroyer: _ember['default'].inject.service(),
    bookingRequestErrorHandler: _ember['default'].inject.service(),
    concurrencyBookingsFetcher: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    unavailabilityEventFetcher: _ember['default'].inject.service(),
    concurrencyEventsFetcher: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    localeProcessor: _ember['default'].inject.service(),
    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    showTemplateUnavailability: false,
    showConcurrencyUnavailability: true,
    compactMode: false,

    isConfiguration: false,

    queryParams: [{ formattedDisplayedDate: 'date' }, { bookingId: 'id' }, 'occurrenceId', 'isConfiguration'],

    bookingId: null,
    occurrenceId: null,
    displayedDate: _ember['default'].computed('formattedDisplayedDate', {
      get: function get() {
        return this.get('formattedDisplayedDate') ? moment(this.get('formattedDisplayedDate')).format('YYYY-MM-DD') : null;
      },
      set: function set(key, value) {
        this.set('formattedDisplayedDate', value ? moment(value).format('YYYY-MM-DD') : '');
      }
    }),

    formattedDisplayedDate: '',

    editRestrictionsModal: null,
    showDayViewCalendarModal: null,

    showBookingRequest: null,
    calendarCurrentViewType: null,
    isMasterView: _ember['default'].computed.equal('calendarCurrentViewType', 'master'),
    lanesToggledOnInit: true,
    viewModeSelected: 'classic',
    showViewModeOptions: true,

    // Left scroll position of master view, so that we can restore it when data is reloaded
    masterViewScrollPosition: 0,

    calendarEvents: _ember['default'].computed('displayedDate', 'model.events.[]', function () {
      var model = this.get('model.events');
      var date = moment(this.get('displayedDate'));
      return model.filter(function (booking) {
        var startDateTime = moment(booking.get('startDateTime')).startOf('day');
        var endDateTime = moment(booking.get('endDateTime')).startOf('day');
        return date.isSame(startDateTime) || date.isSame(endDateTime) || date.isBetween(startDateTime, endDateTime);
      });
    }),

    // Assemble categories for lanesView by getting all the sub objects {name, id}
    masterViewCategories: _ember['default'].computed('model.concurrencyObject.resources', 'model.concurrencyObject.concurrencyGroupMembers', 'isConfiguration', function () {

      var model = this.get('model.concurrencyObject');

      if (this.get('isConfiguration')) {
        var data = model.get('concurrencyGroupMembers').reduce(function (memo, c) {
          var resources = c.resources.map(function (r) {
            return { name: r.resourceName, id: r.resourceIdentity };
          });
          memo.push.apply(memo, _toConsumableArray(resources));
          return memo;
        }, []);

        // remove duplicates
        var uniq = data.uniqBy('id');
        return uniq;
      } else {
        var data = model.get('resources');
        return data.map(function (r) {
          r.extraClassNames = r.isPrimary ? 'chronos-masterView-row--primary' : '';
          return r;
        });
      }
    }),

    viewModes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var modes = ['classic', 'compact', 'contrast'];
      return modes.map(function (m) {
        return { id: m, label: _this.get('i18n').t('pods.manage_resources.resource.show.modes.' + m) };
      });
    }),

    actions: {

      reloadView: function reloadView() {
        this.set('model.events', []);
        this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'), true);
      },

      onViewTypeChanged: function onViewTypeChanged(viewType) {

        if (viewType === this.get('calendarCurrentViewType')) return;

        this.set('calendarCurrentViewType', viewType);

        // if (viewType === 'master') {
        //   this.set('lanesToggledOnInit', true);
        // } else {
        //   this.set('lanesToggledOnInit', false);
        //   this.send('setViewMode', {id: 'classic'});
        // }
      },

      setViewMode: function setViewMode(mode) {

        if (mode.id === 'classic') {
          this.setProperties({ compactMode: false, contrastMode: false });
        } else {
          this.setProperties({ compactMode: true, contrastMode: mode.id === 'contrast' });
        }

        this.setProperties({
          canShowCalendar: false,
          viewModeSelected: mode.id
        });

        this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'));

        this.get('appInsights').trackEvent('concurrency-view-mode', { value: mode.id });
      },

      fetchEvents: function fetchEvents(start, end, forceResync) {
        var _this2 = this;

        this._saveMasterViewScrollPosition();
        this.send('loadingWithoutTransition');

        var model = this.get('model');

        var startDate = moment(start);
        var endDate = moment(end);
        // let resourceTypeId = model.resource.get('resourceTypeId');
        var resourceId = model.concurrencyObject.get('id');

        this.set('currentTimeSpanStart', start);
        this.set('currentTimeSpanEnd', end);

        var eventUnavailabilityPromise = this._fetchUnavailability({ resourceId: resourceId, startDate: startDate, endDate: endDate, forceResync: forceResync });

        var bookingsUnavailabilityPromise = this._fetchBookings({ resourceId: resourceId, startDate: start, endDate: end });

        var promises = [eventUnavailabilityPromise, bookingsUnavailabilityPromise];

        // Create snapshot of the booking that is currently being shown on modal
        var bookingRequestSnapshot = this.get('showBookingRequest') ? this.get('showBookingRequest').serialize() : null;

        _ember['default'].RSVP.all(promises).then(function (evs) {
          var allEvents = evs.reduce(function (memo, events) {
            return memo.concat(events);
          }, []);
          model.events.clear();
          model.events.addObjects(allEvents);
        })['finally'](function () {
          _this2.send('finished');
          _this2.set('canShowCalendar', true);
          // Restore snapshot of booking as to improve performance the call to fetch multiple bookings done above does not calculate permissions
          // If the booking that we are currently displaying is also fetched by the _fetchBookings call we
          // would lose all the permissions making the user unable to click the resource links on the modal
          if (bookingRequestSnapshot) {
            // If the booking request overview is opened then get the serializer and model for booking request and normalise once parsing the stringified booking request snapshot and then push onto the store.
            var serializer = _this2.get('store').serializerFor('booking-request');
            var typeClass = _this2.get('store').modelFor('booking-request');
            var serializedData = serializer.normalize(typeClass, JSON.parse(JSON.stringify(bookingRequestSnapshot)));
            _this2.get('store').push(serializedData);
          }

          _ember['default'].run.later(_this2, function () {
            _this2._restoreMasterViewScrollPosition();
          }, 50);
        })['catch'](function () {
          // no-op
        });
      },

      onEventClick: function onEventClick(chronosEvent) {

        if (chronosEvent.isMasking) return;

        var ev = this.get('model.events').findBy('id', chronosEvent.id);
        if (!ev) return;

        if (ev.availabilityType === 2) {
          this.send('showEventOverview', ev);
          return;
        }

        if (ev.constructor.modelName === 'booking-request') {
          if (chronosEvent.hasRecurrence) {
            this.send('openBookingRequestOverview', _ember['default'].get(ev, 'eventId'), chronosEvent.id);
          } else {
            this.send('openBookingRequestOverview', chronosEvent.id);
          }
        }
      },

      showEventOverview: function showEventOverview(event) {
        var _this3 = this;

        this.send('loadingWithoutTransition');
        this.get('unavailabilityEventFetcher').fetch(this.get('model.resource.resourceTypeId'), this.get('model.resource.id'), event.eventId, event.startDateTime, event.endDateTime).then(function (ev) {
          _this3.set('showEventOverview', ev[0]);
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this3.send('finished');
        });
      },

      openBookingRequestOverview: function openBookingRequestOverview(eventId, occurrenceId) {
        var _this4 = this;

        if (eventId && occurrenceId && occurrenceId !== 'undefined') {
          this.store.queryRecord('booking-request', { bookingId: eventId, occurrenceId: occurrenceId }).then(function (bookingRequest) {
            _this4.set('showBookingRequest', bookingRequest);
          });
        } else {

          this.store.findRecord('booking-request', eventId).then(function (bookingRequest) {
            _this4.set('showBookingRequest', bookingRequest);
          });
        }

        // Clear query params that might have been set after editing a booking
        _ember['default'].run.next(this, function () {
          this.setProperties({
            bookingId: null,
            occurrenceId: null
          });
        });
      },

      openBookingRequestWithModelLoaded: function openBookingRequestWithModelLoaded(bookingRequest) {
        this.set('showBookingRequest', bookingRequest);
        _ember['default'].run.next(this, 'setProperties', { bookingId: null, occurrenceId: null });
      },

      cancelBookingRequest: function cancelBookingRequest(bookingRequest, cancelType) {
        var numberOfOcurrences = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

        var occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
        this.set('bookingRequestScheduledForCancellation', { booking: bookingRequest, cancelType: cancelType, occurrencesCount: occurrencesCount });
      },

      confirmBookingRequestCancellation: function confirmBookingRequestCancellation(bookingRequest, cancelType, note) {
        var _this5 = this;

        this.get('bookingRequestDestroyer').destroyRequest(bookingRequest, cancelType, note).then(function () {
          _this5.deleteSuccessFlash('booking-request');

          var currentUserEmail = _this5.get('currentUser.user.email');
          var bookerEmail = bookingRequest.get('bookerEmailAddress');
          _this5.get('appInsights').trackEvent('booking-cancelled', {
            type: cancelType === 1 ? 'occurrence' : 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequest.get('id'),
            bookingName: bookingRequest.get('name'),
            from: 'concurrency-calendar'
          });

          var model = _this5.get('model').events;

          var modelObjectToRemove = model.findBy('id', bookingRequest.get('id'));
          model.removeObject(modelObjectToRemove);

          if (bookingRequest.get('hasRecurrence') && cancelType === 2) {
            var occurrences = model.filterBy('eventId', bookingRequest.get('eventId'));
            model.removeObjects(occurrences);

            var maskingObjects = model.filterBy('eventId', modelObjectToRemove.get('eventId') + '-masking');
            model.removeObjects(maskingObjects);
          }
        })['catch'](function (error) {
          if (error.responseJSON) {
            // let errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON, 'pods.logged_in.booking_request.index.flash_messages');
            var errorMessage = _this5.get('bookingRequestErrorHandler').handle(error, 'pods.logged_in.booking_request.index.flash_messages');
            errorMessage = errorMessage.replace(new RegExp('\<\/br\>', 'g'), ', ');
            if (errorMessage) {
              _this5.flashError(errorMessage);
              return;
            }
          }

          _this5.deleteErrorFlash('booking-request');
        })['finally'](function () {
          _this5.setProperties({
            showBookingRequest: null,
            bookingRequestScheduledForCancellation: null
          });
        });
      },

      onClickCountBadge: function onClickCountBadge(date) {
        this.send('openDayViewCalendarModal', date);
      },

      openDayViewCalendarModal: function openDayViewCalendarModal(date) {
        this.set('formattedDisplayedDate', date.format('YYYY-MM-DD'));
        this.set('withBookingRequest', true);
        this.set('showDayViewCalendarModal', date);
        this.set('eventTitlePath', 'nameWithFallback');
      },

      dayViewCalendarEventsRefresh: function dayViewCalendarEventsRefresh() {
        var date = this.get('displayedDate');
        var bookingRequestsFiltered = this.filterEventsForDayViewCalendar(date);
        this.set('calendarEvents', bookingRequestsFiltered);
      },

      editBookingRequest: function editBookingRequest(bookingRequest) {
        var _this6 = this;

        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

        var bookingId = bookingRequest.get('eventId');
        var occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;

        var queryParams = {
          bookingId: bookingId,
          date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
          editFromPage: 'concurrency-calendar',
          isConfiguration: this.get('isConfiguration'),
          concurrencyId: this.get('model.concurrencyObject.id'),
          view: this.get('calendarCurrentViewType')

        };

        if (occurrenceId) queryParams.occurrenceId = occurrenceId;

        // If there are no restrictions just proceed to edit
        if (_ember['default'].isEmpty(editRestrictions)) {
          this.send('editBookingRequestConfirm', bookingRequest, queryParams);
          return;
        }

        // Else create a deferred promise which will be resolved or rejected depending if the user chooses to continue with the edit or cancel
        var deferred = _ember['default'].RSVP.defer();

        //If user confirms then proceed to edit
        deferred.promise.then(function () {
          _this6.send('editBookingRequestConfirm', bookingRequest, queryParams);
        })['catch'](function () {
          _this6.set('editRestrictionsModal', null);
        });

        // Trigger the modal and pass in the deferred and restrictions
        this.set('editRestrictionsModal', {
          deferred: deferred,
          restrictions: editRestrictions,
          isOccurrence: singleOccurrenceEdit
        });
      },

      editBookingRequestConfirm: function editBookingRequestConfirm(bookingRequest, queryParams) {

        this.transitionToRoute('logged-in.resource.show', bookingRequest.get('bookingTypeId'), bookingRequest.get('resources.firstObject.id'), { queryParams: queryParams });
      },

      setDisplayedDate: function setDisplayedDate(displayedDate) {
        this.set('formattedDisplayedDate', displayedDate.format('YYYY-MM-DD'));
      },

      toggleTemplateUnavailability: function toggleTemplateUnavailability() {
        this.toggleProperty('showTemplateUnavailability');
        this.get('appInsights').trackConcurrencyToggleTemplateUnavailability(this.get('model.concurrencyObject'), this.get('showTemplateUnavailability'));
        this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'));
      },

      toggleConcurrencyUnavailability: function toggleConcurrencyUnavailability() {
        this.toggleProperty('showConcurrencyUnavailability');
        this.get('appInsights').trackConcurrencyToggleConcurrencyUnavailability(this.get('model.concurrencyObject'), this.get('showConcurrencyUnavailability'));
        this.send('fetchEvents', this.get('currentTimeSpanStart'), this.get('currentTimeSpanEnd'));
      },

      toggleViewModeVisiblity: function toggleViewModeVisiblity(value) {
        this.get('appInsights').trackEvent('concurrency-toggle-lanes-mode', { value: value });
        this.toggleProperty('showViewModeOptions');
      }

    },

    _fetchBookings: function _fetchBookings(_ref) {
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;

      var con = this.get('model.concurrencyObject');

      var isConfiguration = this.get('isConfiguration');

      var resourceIds = [];

      if (isConfiguration) {
        resourceIds = con.get('concurrencyGroupMembers').reduce(function (memo, c) {
          return [].concat(_toConsumableArray(memo), _toConsumableArray(c.resources.getEach('resourceIdentity')));
        }, []);
      } else {
        resourceIds = con.get('resources').getEach('id');
      }

      var isMasterView = this.get('isMasterView');
      var isCompactMode = this.get('compactMode');

      return this.get('concurrencyBookingsFetcher').fetch({
        resourceIds: resourceIds,
        startDate: startDate,
        endDate: endDate,
        allowDuplicates: isMasterView
      }).then(function (bookings) {
        return bookings.map(function (booking) {
          booking.setProperties({
            tag: UNAVAILABILITY_TYPES[3].capitalize(),
            extraClassNames: 'chronos-event--' + booking.get('status').toLowerCase() + ' ' + (isCompactMode ? 'chronos-event--compactMode' : '') });
          return booking;
        });
      });
    },

    _fetchUnavailability: function _fetchUnavailability(_ref2) {
      var resourceId = _ref2.resourceId;
      var startDate = _ref2.startDate;
      var endDate = _ref2.endDate;
      var forceResync = _ref2.forceResync;

      var isConcurrencyConfiguration = this.get('isConfiguration');
      var includeBookingTypes = this.get('showTemplateUnavailability');
      var includeConcurrencyGroups = this.get('showConcurrencyUnavailability');
      var isMasterView = this.get('isMasterView');

      var concurrencyConfigurationIds = isConcurrencyConfiguration ? [resourceId] : [];
      var concurrencyGroupIds = isConcurrencyConfiguration ? [] : [resourceId];

      return this.get('concurrencyEventsFetcher').fetch({
        concurrencyGroupIds: concurrencyGroupIds,
        concurrencyConfigurationIds: concurrencyConfigurationIds,
        isConcurrencyConfiguration: isConcurrencyConfiguration,
        startDate: startDate,
        endDate: endDate,
        forceResync: forceResync,
        includeBookingTypes: includeBookingTypes,
        includeConcurrencyGroups: includeConcurrencyGroups,
        allowDuplicates: isMasterView
      }).then(function (evs) {

        return evs.filter(function (event) {
          return event.availabilityType !== 3;
        }).reduce(function (memo, event) {

          var isPattern = event.repeats;

          var startDateTime = undefined;

          // If it's a pattern, we need to keep the date intact to match the correct day of the week)
          // but adjust the time to local
          if (isPattern) {
            var localStart = moment(event.startDateTime);

            var startDateTimeSplit = event.startDateTime.split('-');
            var year = startDateTimeSplit[0];
            var month = parseInt(startDateTimeSplit[1]) - 1;
            var date = startDateTimeSplit[2].split('T');
            localStart.year(year).month(month).date(date[0]);
            startDateTime = localStart;
          } else {
            startDateTime = moment(event.startDateTime);
          }

          var endDateTime = startDateTime.clone().add(event.duration, 'minutes');
          var availabilityType = event.availabilityType;
          var slot = _ember['default'].Object.create({
            id: availabilityType === 4 ? null : event.id,
            eventId: event.eventId,
            tag: UNAVAILABILITY_TYPES[event.availabilityType].capitalize(),
            name: event.availabilityType === 5 ? event.concurrencyResourceName : event.name || null,
            availabilityType: event.availabilityType,
            resourceIdLanesViewVariant: event.resourceIdLanesViewVariant,
            extraClassNames: 'chronos-unavailability ' + (isPattern ? 'chronos-unavailability-pattern' : ''),
            startDateTime: startDateTime,
            endDateTime: endDateTime
          });

          // let slotMasking = Ember.Object.create({
          //   id: `${event.id}-masking`,
          //   isMasking: true,
          //   startDateTime,
          //   endDateTime});

          memo.pushObjects([slot /*, slotMasking*/]);
          return memo;
        }, []);
      });
    },

    _saveMasterViewScrollPosition: function _saveMasterViewScrollPosition() {

      var container = document.querySelector('.chronos-masterView');
      this.set('masterViewScrollPosition', container ? container.scrollLeft : 0);
    },

    _restoreMasterViewScrollPosition: function _restoreMasterViewScrollPosition() {
      var currentValue = this.get('masterViewScrollPosition');
      var container = document.querySelector('.chronos-masterView');

      if (container && currentValue) {
        container.scrollLeft = currentValue;
        this.set('masterViewScrollPosition', 0);
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('i18n').t('pods.manage_resources.resource_type.concurrency.index.page_title'),
        path: 'manage-resources.resource-type.concurrency.index'
      }, {
        label: this.get('model.concurrencyObject.name'),
        linkable: false
      }];
    }).property('model.concurrencyObject.name', 'i18n.locale')
  });
});