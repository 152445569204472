define('scientia-resourcebooker/pods/components/system/system-settings/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tabForm at-systemSettings'],
    options: null,
    editorOptions: _ember['default'].computed('elementId', function () {
      return {
        'font_formats': 'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Calibri=Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        'toolbar1': 'insertfile undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview',
        'convert_urls': true,
        'relative_urls': false,
        'menubar': true,
        'remove_script_host': false,
        'valid_children': '+body[style]'
      };
    }),

    isSaving: false,

    actions: {
      changeMode: function changeMode(mode) {
        this.sendAction('changeMode', mode);
      },

      save: function save() {
        this.sendAction('save');
      }
    }

  });
});