define('scientia-resourcebooker/pods/components/week-time-picker/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    busyPatterns: [],
    systemSettings: _ember['default'].inject.service(),

    weekdays: (function () {

      var weekDays = this.get('i18n').t('general.weekdays').toString().split(',');
      var weekDayOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');

      var busyPatterns = this.get('busyPatterns');
      var result = weekDays.map(function (name, index) {

        var indexOffset = index - weekDayOffset;
        indexOffset = indexOffset < 0 ? indexOffset + 7 : indexOffset;
        return {
          name: name,
          busyPatterns: busyPatterns[index],
          index: index,
          sortedIndex: indexOffset
        };
      });

      return result;
    }).property('busyPatterns'),

    hours: (function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var startIndex = 0;
      var endIndex = 24;

      for (var index = startIndex; index < endIndex; index++) {
        hours.push(startTime.clone().add(index, 'hours'));
      }

      return hours;
    }).property(),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.sendAction('onInsertElement', moment(), moment());
      });
    }).on('init'),

    actions: {
      showAddSlot: function showAddSlot(weekdayIndex, hour) {
        var slot = {
          dayIndex: weekdayIndex,
          isRepeat: true,
          specificDate: moment().startOf('day').day(weekdayIndex),
          fromTime: hour.hour() * 60,
          toTime: (hour.hour() + 1) * 60
        };
        this.get('showAddSlot')(slot);
      }

    }

    // Drag events
    // Array.from(document.querySelectorAll('.chronos-weekView-hourGrid-item')).forEach(el => {
    //   el.addEventListener('dragstart', function() {
    //     el.classList.add('chronos-weekView-hourGrid-item--highlighted')
    //     });
    // el.addEventListener('dragenter', function() {
    //     el.classList.add('chronos-weekView-hourGrid-item--highlighted')
    //     });
    // });
  });
});