define('scientia-resourcebooker/pods/components/manage-resources/add-unavailability-weekday-slot/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    userGroup: null,
    isSaving: false,

    model: null,

    formattedDate: _ember['default'].computed('model.dayIndex', function () {
      if (this.get('model.dayIndex') === null) return;
      return moment().day(this.get('model.dayIndex')).format('dddd');
    }),

    fromHours: _ember['default'].computed(function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var startIndex = 0;
      var endIndex = 23;

      var minutes = 0;
      var minutesFromStartOfDay = 0;

      for (var hourIndex = startIndex; hourIndex <= endIndex; hourIndex++) {
        for (var minutesIndex = 0; minutesIndex < 60; minutesIndex += 15) {
          var time = startTime.clone().add(minutes, 'minutes');

          hours.push({ id: minutes, value: minutes, label: time.format(this.get('i18n').t('components.time_picker.hour_format').toString()) });
          minutes += 15;
          minutesFromStartOfDay += 15;
        }
      }

      return hours;
    }),

    toHours: _ember['default'].computed(function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var startIndex = 1;
      var endIndex = 24;

      var minutes = 0;

      for (var hourIndex = startIndex; hourIndex <= endIndex; hourIndex++) {
        for (var minutesIndex = 0; minutesIndex < 60; minutesIndex += 15) {
          var time = startTime.clone().add(minutes, 'minutes');
          hours.push({ id: minutes, value: minutes, label: time.format(this.get('i18n').t('components.time_picker.hour_format').toString()) });
          minutes += 15;
        }
      }

      hours.shift();

      // Insert midnight (24:00)
      hours.push({ id: 1440, value: 1440, label: this.get('i18n').t('date_formats.midnight_twenty_four').toString() });

      return hours;
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('error', null);
        this.set('isSaving', true);

        var duration = this.get('model.toTime') - this.get('model.fromTime');
        if (duration <= 0) {
          this.set('error', this.get('i18n').t('components.add_unavailability_slot.end_cannot_be_before'));
          this.set('isSaving', false);
          return;
        }

        this.get('saveUnavailabilityWeekdaySlot')(this.get('model'), this.get('userGroup'))['catch'](function (conflictingSlot) {

          var startOfDay = moment('2015-01-01').startOf('day');
          var startTime = startOfDay.clone().add(conflictingSlot.fromTime.asMinutes(), 'minute').format(_this.get('i18n').t('date_formats.hour_full'));
          var endTime = startOfDay.clone().add(conflictingSlot.toTime.asMinutes(), 'minute').format(_this.get('i18n').t('date_formats.hour_full'));

          var error = _this.get('i18n').t('components.add_unavailability_slot.slot_conflict', { startTime: startTime, endTime: endTime });

          _this.set('error', error);
          _this.set('isSaving', false);
        });
      }
    }
  });
});