define('scientia-resourcebooker/services/unavailability-updater', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    authenticatedRequest: _ember['default'].inject.service(),

    update: function update(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var patternSlots = _ref.patternSlots;
      var specificDateSlots = _ref.specificDateSlots;

      var data = { availabilityPatternsToAdd: [], availabilityPatternsToRemove: [] };

      patternSlots.forEach(function (day) {
        day.forEach(function (slot) {
          var strippedSlot = _this._stripSlotData(slot);
          if (slot.isPendingAdd) data.availabilityPatternsToAdd.push(strippedSlot);
          if (slot.isPendingRemove) data.availabilityPatternsToRemove.push(strippedSlot);
        });
      });

      specificDateSlots.forEach(function (slot) {
        var strippedSlot = _this._stripSlotData(slot);
        if (slot.isPendingAdd) data.availabilityPatternsToAdd.push(strippedSlot);
        if (slot.isPendingRemove) data.availabilityPatternsToRemove.push(strippedSlot);
      });

      var url = this._getUrl(type, id, parentId);

      return this.get('authenticatedRequest').put(url, data);
    },

    _stripSlotData: function _stripSlotData(slot) {
      if (slot.isPendingRemove) return slot.data;

      return {
        repeat: slot.isRepeat,
        days: slot.isRepeat ? [slot.data.weekdayIndex] : null,
        duration: slot.data.duration,
        startDateTime: slot.data.specificDate.format()
      };
    },

    _getUrl: function _getUrl(type, id, parentId) {
      var baseUrl = _scientiaResourcebookerConfigEnvironment['default'].API.UNAVAILABILITY_UPDATE[type];
      var url = undefined;

      if (parentId) {
        url = baseUrl.loc(parentId, id);
      } else {
        url = baseUrl.loc(id);
      }

      // This will be used when the user group are listed in the component.
      //if (userGroup) url = `${url}?userGroupIdentity=[${userGroup}]`;

      return url;
    }
  });
});