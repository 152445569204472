define('scientia-resourcebooker/pods/components/booking-report-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingReportListItem'],
    model: null,

    bookingTimeFormat: _ember['default'].computed('i18n.locale', 'model.spanMultipleDays', function () {
      var isMultipleDay = this.get('model.spansMultipleDays');
      var format = 'components.booking_report_list.' + (isMultipleDay ? 'date_format_span' : 'time_format');
      return this.get('i18n').t(format);
    }),

    bookingEndTimeSpanFormat: _ember['default'].computed('i18n.locale', 'model.spanMultipleDays', function () {
      var format = 'components.booking_report_list.date_format_span_twenty_four';
      return this.get('i18n').t(format);
    }),

    loadingBookingRequest: false,
    isLoadingBookingRequest: _ember['default'].computed('loadingBookingRequest', 'model.id', function () {
      return this.get('loadingBookingRequest') === this.get('model.id');
    })
  });
});