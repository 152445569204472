define('scientia-resourcebooker/pods/components/week-time-pattern/component', ['exports', 'ember'], function (exports, _ember) {

  var SLOT_DURATION_IN_SECONDS = 1800; // 30 minutes
  var ONE_HOUR_IN_SECONDS = 3600;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['chronos-weekView-event at-weekTimePattern'],
    model: null,

    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        var model = this.get('model');
        var fromTime = model.fromTime;
        var toTime = model.toTime;

        var secondDiff = toTime.asSeconds() - fromTime.asSeconds();
        var span = Math.round(secondDiff / SLOT_DURATION_IN_SECONDS * 10) / 10;

        var hourSlotHeight = _ember['default'].$('.' + this.get('slotClassName')).outerHeight();

        var top = model.fromTime.asHours() * hourSlotHeight;

        var topOffset = 0;
        var bottomOffset = 24 * hourSlotHeight;
        var maxHeight = bottomOffset - topOffset - top;
        var height = Math.min(span * hourSlotHeight / (ONE_HOUR_IN_SECONDS / SLOT_DURATION_IN_SECONDS), maxHeight);

        this.$().css({ height: height, top: top });

        this.$().addClass(model.isPendingAdd ? 'chronos-event--pendingAdd' : '');
        this.$().addClass(model.isPendingRemove ? 'chronos-event--pendingRemove' : '');

        // If 15min slot or less
        if (height <= 10) {
          this.$().addClass('chronos-weekView-event--tiny');
        }
      });
    }).on('init'),

    click: function click() {
      var model = this.get('model');

      if (model.isPendingAdd) return this.get('undoAddSlot')(model);
      if (model.isPendingRemove) return this.get('undoRemoveSlot')(model);

      return this.get('showRemoveSlot')(model);
    }
  });
});