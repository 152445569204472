define("scientia-resourcebooker/pods/manage-resources/user-group/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["confirmTitle", ["subexpr", "t", ["pods.manage_resources.user_group.index.confirm_delete_title"], [], ["loc", [null, [7, 19], [7, 84]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.user_group.index.confirm_delete_message"], [], ["loc", [null, [8, 21], [8, 88]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteUserGroup", ["loc", [null, [9, 25], [9, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 52]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 59]]], 0, 0], "confirm", ["subexpr", "action", ["confirmDeleteUserGroup"], [], ["loc", [null, [10, 14], [10, 47]]], 0, 0]], ["loc", [null, [5, 9], [10, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteUserGroup", ["loc", [null, [11, 32], [11, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 27], [11, 59]]], 0, 0], false], [], ["loc", [null, [11, 19], [11, 66]]], 0, 0]], ["loc", [null, [4, 7], [11, 67]]], 0, 0]], ["loc", [null, [2, 2], [11, 69]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 10
                },
                "end": {
                  "line": 33,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["icon", "icons/users.svg", "text", ["subexpr", "t", ["pods.manage_resources.user_group.index.new_user_group"], [], ["loc", [null, [31, 17], [31, 76]]], 0, 0], "routeTarget", "manage-resources.user-group.new"], ["loc", [null, [29, 12], [32, 59]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 10
                },
                "end": {
                  "line": 41,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["icon", "icons/upload.svg", "text", ["subexpr", "t", ["pods.manage_resources.user_group.index.import"], [], ["loc", [null, [39, 17], [39, 68]]], 0, 0], "routeTarget", "manage-resources.user-group.importer"], ["loc", [null, [37, 12], [40, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 10
                },
                "end": {
                  "line": 49,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["icon", "icons/search.svg", "text", ["subexpr", "t", ["pods.manage_resources.user_group.index.user_lookup"], [], ["loc", [null, [47, 17], [47, 73]]], 0, 0], "routeTarget", "manage-resources.user-lookup"], ["loc", [null, [45, 12], [48, 56]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "can", ["create user-group"], [], ["loc", [null, [28, 16], [28, 41]]], 0, 0]], [], 0, null, ["loc", [null, [28, 10], [33, 17]]]], ["block", "if", [["subexpr", "can", ["import user-group"], [], ["loc", [null, [36, 16], [36, 41]]], 0, 0]], [], 1, null, ["loc", [null, [36, 10], [41, 15]]]], ["block", "if", [["subexpr", "can", ["access admin"], [], ["loc", [null, [44, 16], [44, 36]]], 0, 0]], [], 2, null, ["loc", [null, [44, 10], [49, 15]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "sectionTitle-content");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "src", "icons/users.svg");
          dom.setAttribute(el2, "class", "sectionTitle-icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sectionTitle-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["pods.manage_resources.user_group.index.user_groups"], [], ["loc", [null, [21, 40], [21, 98]]], 0, 0], ["block", "section-title-actions", [], [], 0, null, ["loc", [null, [24, 6], [51, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 10
                },
                "end": {
                  "line": 59,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["pods.manage_resources.user_group.index.custom"], [], ["loc", [null, [58, 12], [58, 65]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 12
                  },
                  "end": {
                    "line": 64,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.manage_resources.user_group.index.roles"], [], ["loc", [null, [63, 14], [63, 66]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 10
                },
                "end": {
                  "line": 65,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [62, 12], [64, 23]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 8
              },
              "end": {
                "line": 66,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [57, 10], [59, 21]]]], ["block", "if", [["get", "isAdmin", ["loc", [null, [61, 16], [61, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [61, 10], [65, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 6
                },
                "end": {
                  "line": 92,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.userGroups.meta.currentPage", ["loc", [null, [87, 20], [87, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.userGroups.meta.totalPages", ["loc", [null, [88, 19], [88, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [90, 21], [90, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [86, 8], [91, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 5
              },
              "end": {
                "line": 93,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "manage-resources/resources-list/search", [], ["query", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [71, 16], [71, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.resources_list.search.search_groups"], [], ["loc", [null, [72, 22], [72, 74]]], 0, 0]], ["loc", [null, [70, 8], [73, 10]]], 0, 0], ["inline", "manage-resources/item-types-list", [], ["items", ["subexpr", "filter-by", ["isSystemGroup", false, ["get", "model.userGroups", ["loc", [null, [76, 49], [76, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [76, 16], [76, 66]]], 0, 0], "deleteItemType", ["subexpr", "action", ["deleteUserGroup"], [], ["loc", [null, [77, 25], [77, 51]]], 0, 0], "type", "user-group", "iconUri", "icons/users.svg", "showItemPath", "manage-resources.user-group.edit", "editItemPath", "manage-resources.user-group.edit", "hideShowAction", true], ["loc", [null, [75, 8], [83, 10]]], 0, 0], ["block", "if", [["get", "shouldShowPaging", ["loc", [null, [85, 12], [85, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [85, 6], [92, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 8
                },
                "end": {
                  "line": 107,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/item-types-list", [], ["items", ["subexpr", "@mut", [["get", "systemGroups", ["loc", [null, [98, 18], [98, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "deleteItemType", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [99, 45], [99, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 35], [99, 50]]], 0, 0]], [], ["loc", [null, [99, 27], [99, 51]]], 0, 0], "type", "user-group", "iconUri", "icons/users.svg", "showItemPath", "manage-resources.user-group.edit", "editItemPath", "manage-resources.user-group.edit", "hideShowAction", true, "hideDeleteAction", true], ["loc", [null, [97, 10], [106, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 95,
                "column": 6
              },
              "end": {
                "line": 108,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-panel", [], ["class", "at-userGroups"], 0, null, ["loc", [null, [96, 8], [107, 25]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 110,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [56, 8], [66, 24]]]], ["block", "dc-tab-panel", [], ["class", "at-userGroups"], 1, null, ["loc", [null, [68, 5], [93, 23]]]], ["block", "if", [["get", "isAdmin", ["loc", [null, [95, 12], [95, 19]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [95, 6], [108, 13]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 113,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-resourceTypesList");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showConfirmDeleteUserGroup", ["loc", [null, [1, 6], [1, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [15, 39], [15, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 0], [15, 53]]], 0, 0], ["block", "section-title", [], ["location", "at-manageResourcesResourceTypesList"], 1, null, ["loc", [null, [18, 4], [53, 22]]]], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth at-userGroups", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [55, 71], [55, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [55, 4], [110, 16]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});