define("scientia-resourcebooker/pods/components/resource-tree/search/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "search", "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [2, 33], [2, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [2, 48], [2, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "key-down", ["subexpr", "action", [["get", "onSearch", ["loc", [null, [2, 71], [2, 79]]], 0, 0, 0, 0], ["get", "query", ["loc", [null, [2, 80], [2, 85]]], 0, 0, 0, 0], ["get", "typeSelected", ["loc", [null, [2, 86], [2, 98]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 63], [2, 99]]], 0, 0], "placeholder", ["subexpr", "if", [["get", "queryPlaceholder", ["loc", [null, [2, 116], [2, 132]]], 0, 0, 0, 0], ["get", "queryPlaceholder", ["loc", [null, [2, 133], [2, 149]]], 0, 0, 0, 0], ["subexpr", "t", ["components.resource_tree.search.search_placeholder"], [], ["loc", [null, [2, 150], [2, 206]]], 0, 0]], [], ["loc", [null, [2, 112], [2, 207]]], 0, 0], "class", "form-input resourceTree-search-input"], ["loc", [null, [2, 2], [2, 254]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "search", "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [4, 32], [4, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "query", ["loc", [null, [4, 47], [4, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", ["subexpr", "action", [["get", "onSearch", ["loc", [null, [4, 67], [4, 75]]], 0, 0, 0, 0], ["get", "query", ["loc", [null, [4, 76], [4, 81]]], 0, 0, 0, 0], ["get", "typeSelected", ["loc", [null, [4, 82], [4, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 59], [4, 95]]], 0, 0], "placeholder", ["subexpr", "if", [["get", "queryPlaceholder", ["loc", [null, [4, 112], [4, 128]]], 0, 0, 0, 0], ["get", "queryPlaceholder", ["loc", [null, [4, 129], [4, 145]]], 0, 0, 0, 0], ["subexpr", "t", ["components.resource_tree.search.search_placeholder"], [], ["loc", [null, [4, 146], [4, 202]]], 0, 0]], [], ["loc", [null, [4, 108], [4, 203]]], 0, 0], "class", "form-input resourceTree-search-input"], ["loc", [null, [4, 1], [4, 250]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "types", ["loc", [null, [9, 12], [9, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [10, 13], [10, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "typeSelected", ["loc", [null, [11, 10], [11, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [12, 16], [12, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "showPlaceholder", true, "optionLabelPath", "label", "optionValuePath", "value", "class", "at-bookingReportsSearchQuery"], ["loc", [null, [8, 2], [17, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "button button--white button--withIcon sectionTitle-search-button at-notification-searcher");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createAttrMorph(element0, 'disabled');
        morphs[3] = dom.createElementMorph(element0);
        morphs[4] = dom.createMorphAt(element0, 1, 1);
        morphs[5] = dom.createMorphAt(element0, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "searchOnKeyDown", ["loc", [null, [1, 6], [1, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [5, 7]]]], ["block", "if", [["get", "types", ["loc", [null, [7, 6], [7, 11]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [7, 0], [18, 7]]]], ["attribute", "disabled", ["subexpr", "or", [["get", "searchDisabled", ["loc", [null, [20, 70], [20, 84]]], 0, 0, 0, 0], ["get", "disabled", ["loc", [null, [20, 85], [20, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [20, 95]]], 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "onSearch", ["loc", [null, [20, 25], [20, 33]]], 0, 0, 0, 0], ["get", "query", ["loc", [null, [20, 34], [20, 39]]], 0, 0, 0, 0], ["get", "typeSelected", ["loc", [null, [20, 40], [20, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 17], [20, 53]]], 0, 0]], [], ["loc", [null, [20, 8], [20, 55]]], 0, 0], ["inline", "inline-svg", ["icons/search.svg"], ["class", "button-icon at-sectionTitle-button--white"], ["loc", [null, [21, 2], [21, 85]]], 0, 0], ["inline", "t", ["components.resource_tree.search.search"], [], ["loc", [null, [22, 2], [22, 48]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});