define("scientia-resourcebooker/pods/components/manage-resources/claims-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["get", "titleLabel", ["loc", [null, [3, 8], [3, 18]]], 0, 0, 0, 0]], ["name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [3, 24], [3, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [3, 30]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["get", "titleLabel", ["loc", [null, [5, 8], [5, 18]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 4], [5, 20]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle manageResources-itemForm-subtitle--disabledTab");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element9, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [11, 2], [11, 81]]], 0, 0], ["inline", "t", ["components.manage_resources.user_group_form.deprecated_role"], [], ["loc", [null, [12, 10], [12, 77]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 175
                },
                "end": {
                  "line": 27,
                  "column": 251
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "loading-icon", [], ["location", "at-permissionSummaryLink"], ["loc", [null, [27, 199], [27, 251]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 29,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "manageResources-permissionsForm-permissionSummary");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "link");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(element7, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element7, 1, 1);
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createMorphAt(element8, 0, 0);
            morphs[3] = dom.createMorphAt(element7, 5, 5);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "manageResources-permissionsForm-permissionSummary-icon"], ["loc", [null, [27, 10], [27, 105]]], 0, 0], ["element", "action", ["showClaims"], [], ["loc", [null, [27, 122], [27, 145]]], 0, 0], ["inline", "t", ["general.continue"], [], ["loc", [null, [27, 146], [27, 170]]], 0, 0], ["block", "if", [["get", "isLoadingClaims", ["loc", [null, [27, 181], [27, 196]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 175], [27, 258]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 30,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "booking-type-notification-banner", [], ["location", "at-claimsForm", "model", ["subexpr", "hash", [], ["info", true, "message", ["subexpr", "t", ["components.manage_resources.claims_form.too_many_claims"], ["count", ["get", "claims.length", ["loc", [null, [24, 83], [24, 96]]], 0, 0, 0, 0]], ["loc", [null, [24, 16], [24, 97]]], 0, 0]], ["loc", [null, [22, 12], [24, 98]]], 0, 0]], 0, null, ["loc", [null, [20, 6], [29, 43]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 8
                },
                "end": {
                  "line": 46,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/claims-form/claim-value", [], ["claim", ["subexpr", "@mut", [["get", "claim", ["loc", [null, [45, 59], [45, 64]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 10], [45, 66]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 8
                },
                "end": {
                  "line": 48,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" - ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element1, 0, 0);
              morphs[1] = dom.createMorphAt(element1, 2, 2);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "concat-strings", ["components.manage_resources.claims_form.claim_types.", ["get", "claim.claimType", ["loc", [null, [47, 86], [47, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 15], [47, 102]]], 0, 0]], [], ["loc", [null, [47, 11], [47, 104]]], 0, 0], ["content", "claim.claimValue", ["loc", [null, [47, 107], [47, 127]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 50,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "buttonGroup manageResources-itemForm-form-groupActions");
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "class", "button button--white at-buttonGroup at-confirmEdit at-claimsForm");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "class", "button button--white at-buttonGroup at-claimsForm");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "class", "button button--white at-buttonGroup at-claimsForm");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element3, [1]);
            var element5 = dom.childAt(element3, [3]);
            var element6 = dom.childAt(element3, [5]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createAttrMorph(element4, 'disabled');
            morphs[2] = dom.createElementMorph(element4);
            morphs[3] = dom.createMorphAt(element4, 0, 0);
            morphs[4] = dom.createElementMorph(element5);
            morphs[5] = dom.createMorphAt(element5, 0, 0);
            morphs[6] = dom.createElementMorph(element6);
            morphs[7] = dom.createMorphAt(element6, 0, 0);
            morphs[8] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["manageResources-itemForm-form-group ", ["subexpr", "if", [["get", "claim.isEditing", ["loc", [null, [35, 58], [35, 73]]], 0, 0, 0, 0], "manageResources-itemForm-form-group--isEditing", ""], [], ["loc", [null, [35, 52], [35, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "not", [["get", "claim.claimValue", ["loc", [null, [38, 31], [38, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [38, 49]]], 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "claim.isEditing", ["loc", [null, [38, 72], [38, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 67], [38, 88]]], 0, 0], false], [], ["loc", [null, [38, 59], [38, 95]]], 0, 0]], [], ["loc", [null, [38, 50], [38, 97]]], 0, 0], ["inline", "t", ["general.done"], [], ["loc", [null, [38, 185], [38, 205]]], 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "claim.isEditing", ["loc", [null, [39, 38], [39, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 33], [39, 54]]], 0, 0], true], [], ["loc", [null, [39, 25], [39, 60]]], 0, 0]], [], ["loc", [null, [39, 16], [39, 62]]], 0, 0], ["inline", "inline-svg", ["icons/pencil.svg"], ["class", "manageResources-itemForm-form-groupActions-icon"], ["loc", [null, [39, 135], [39, 224]]], 0, 0], ["element", "action", ["deleteClaim", ["get", "index", ["loc", [null, [41, 39], [41, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 16], [41, 46]]], 0, 0], ["inline", "inline-svg", ["icons/trash.svg"], ["class", "manageResources-itemForm-form-groupActions-icon"], ["loc", [null, [41, 119], [41, 207]]], 0, 0], ["block", "if", [["get", "claim.isEditing", ["loc", [null, [44, 14], [44, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [44, 8], [48, 15]]]]],
          locals: ["claim", "index"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 2
              },
              "end": {
                "line": 58,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "manageResources-itemForm-form-item");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "manageResources-itemForm-form-emptyInstruction");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["components.manage_resources.claims_form.add_item_instruction"], [], ["loc", [null, [55, 8], [55, 76]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 2
            },
            "end": {
              "line": 59,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "claims", ["loc", [null, [34, 10], [34, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [34, 2], [58, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 0
            },
            "end": {
              "line": 66,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-actions");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "button at-claimsForm-action button--primary");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["addClaim"], [], ["loc", [null, [64, 12], [64, 33]]], 0, 0], ["inline", "t", ["components.manage_resources.claims_form.add_item"], [], ["loc", [null, [64, 100], [64, 156]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "style", "position: relative");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element10, 'class');
        morphs[1] = dom.createMorphAt(element10, 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
        morphs[4] = dom.createMorphAt(element11, 3, 3);
        morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["manageResources-itemForm-title at-claimsForm ", ["subexpr", "if", [["get", "isDeprecatedRole", ["loc", [null, [1, 61], [1, 77]]], 0, 0, 0, 0], "manageResources-itemForm-title--hasSubtitle", ""], [], ["loc", [null, [1, 56], [1, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "name", ["loc", [null, [2, 8], [2, 12]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [6, 9]]]], ["block", "if", [["get", "isDeprecatedRole", ["loc", [null, [9, 6], [9, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [9, 0], [14, 7]]]], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "showClaims", ["loc", [null, [19, 18], [19, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 13], [19, 29]]], 0, 0], ["subexpr", "gte", [["get", "claims.length", ["loc", [null, [19, 35], [19, 48]]], 0, 0, 0, 0], 300], [], ["loc", [null, [19, 30], [19, 53]]], 0, 0]], [], ["loc", [null, [19, 8], [19, 54]]], 0, 0]], [], 3, null, ["loc", [null, [19, 2], [30, 9]]]], ["block", "if", [["get", "showClaims", ["loc", [null, [33, 8], [33, 18]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [33, 2], [59, 9]]]], ["block", "if", [["get", "showClaims", ["loc", [null, [62, 6], [62, 16]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [62, 0], [66, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});