define("scientia-resourcebooker/pods/manage-resources/resource-type/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/icon-selector"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [7, 25], [7, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 42]]], 0, 0], false], [], ["loc", [null, [7, 12], [7, 49]]], 0, 0], "submit", ["subexpr", "action", ["setIcon"], [], ["loc", [null, [8, 13], [8, 31]]], 0, 0]], ["loc", [null, [5, 9], [8, 32]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [9, 32], [9, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 27], [9, 49]]], 0, 0], false], [], ["loc", [null, [9, 19], [9, 56]]], 0, 0]], ["loc", [null, [4, 7], [9, 57]]], 0, 0]], ["loc", [null, [2, 0], [10, 2]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 8
                },
                "end": {
                  "line": 39,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["type", "danger", "icon", "icons/trash.svg", "disabled", ["subexpr", "@mut", [["get", "model.resourceType.hasAssociatedBookingTypes", ["loc", [null, [36, 19], [36, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "text", ["subexpr", "t", ["pods.manage_resources.resource_type.edit.delete_resource_type"], [], ["loc", [null, [37, 15], [37, 82]]], 0, 0], "action", ["subexpr", "action", ["deleteModel"], [], ["loc", [null, [38, 17], [38, 39]]], 0, 0]], ["loc", [null, [33, 10], [38, 41]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            return morphs;
          },
          statements: [["inline", "section-title-actions/item", [], ["icon", "icons/tag.svg", "text", ["subexpr", "t", ["pods.manage_resources.resource_type.edit.view_resource_type"], [], ["loc", [null, [27, 15], [27, 80]]], 0, 0], "routeTarget", "manage-resources.resource-type.show", "routeModel", ["subexpr", "array-from", [["get", "model.resourceType.id", ["loc", [null, [29, 33], [29, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 21], [29, 55]]], 0, 0]], ["loc", [null, [25, 10], [29, 57]]], 0, 0], ["block", "if", [["subexpr", "can", ["delete resource-type"], ["model", ["get", "model.resourceType", ["loc", [null, [32, 48], [32, 66]]], 0, 0, 0, 0]], ["loc", [null, [32, 14], [32, 67]]], 0, 0]], [], 0, null, ["loc", [null, [32, 8], [39, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 4
            },
            "end": {
              "line": 43,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [22, 6], [41, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 10
                },
                "end": {
                  "line": 49,
                  "column": 173
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [49, 52], [49, 113]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.edit.description"], [], ["loc", [null, [49, 113], [49, 173]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 10
                },
                "end": {
                  "line": 50,
                  "column": 172
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/edit.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [50, 53], [50, 110]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.edit.custom_fields"], [], ["loc", [null, [50, 110], [50, 172]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 10
                },
                "end": {
                  "line": 51,
                  "column": 170
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [51, 52], [51, 110]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.edit.permissions"], [], ["loc", [null, [51, 110], [51, 170]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 52,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditDescription", ["loc", [null, [49, 31], [49, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 26], [49, 50]]], 0, 0]], 0, null, ["loc", [null, [49, 10], [49, 184]]]], ["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditCustomFields", ["loc", [null, [50, 31], [50, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 26], [50, 51]]], 0, 0]], 1, null, ["loc", [null, [50, 10], [50, 183]]]], ["block", "dc-tab", [], ["faded", ["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [51, 31], [51, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 26], [51, 50]]], 0, 0]], 2, null, ["loc", [null, [51, 10], [51, 181]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 8
              },
              "end": {
                "line": 63,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/resource-type-form", [], ["localePrefix", "components.manage_resources.resource_type_edit", "openIconSelector", "openIconSelector", "clearImageUri", "clearImageUri", "name", ["subexpr", "@mut", [["get", "model.resourceType.name", ["loc", [null, [59, 17], [59, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "imageUri", ["subexpr", "@mut", [["get", "model.resourceType.imageUri", ["loc", [null, [60, 21], [60, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditDescription", ["loc", [null, [61, 26], [61, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 21], [61, 45]]], 0, 0]], ["loc", [null, [55, 10], [62, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/resource-type-custom-fields-form", [], ["localePrefix", "components.manage_resources.resource_type_custom_fields_edit", "name", ["subexpr", "@mut", [["get", "model.resourceType.name", ["loc", [null, [68, 17], [68, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "reorderItems", ["subexpr", "action", ["reorderCustomFields"], [], ["loc", [null, [69, 25], [69, 55]]], 0, 0], "properties", ["subexpr", "@mut", [["get", "model.resourceType.properties", ["loc", [null, [70, 23], [70, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditCustomFields", ["loc", [null, [71, 26], [71, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 21], [71, 46]]], 0, 0]], ["loc", [null, [66, 10], [72, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 10
                },
                "end": {
                  "line": 82,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "manageResources-itemForm-subtitle at-permissionsForm manageResources-itemForm-subtitle--disabledTab");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/warning.svg"], ["class", "at-manageResources-itemForm-subtitle"], ["loc", [null, [79, 14], [79, 93]]], 0, 0], ["inline", "t", ["pods.manage_resources.tab_no_permission"], [], ["loc", [null, [80, 20], [80, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 89,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["manageResources-itemForm-title at-permissionsForm ", ["subexpr", "if", [["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [76, 81], [76, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [76, 76], [76, 100]]], 0, 0], "manageResources-itemForm-title--hasSubtitle", ""], [], ["loc", [null, [76, 71], [76, 151]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.edit.permissions_title"], ["name", ["subexpr", "@mut", [["get", "model.resourceType.name", ["loc", [null, [76, 223], [76, 246]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [76, 153], [76, 248]]], 0, 0], ["block", "if", [["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [77, 21], [77, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 16], [77, 40]]], 0, 0]], [], 0, null, ["loc", [null, [77, 10], [82, 17]]]], ["inline", "manage-resources/permissions-form", [], ["permissions", ["subexpr", "@mut", [["get", "permissions", ["loc", [null, [84, 22], [84, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "permissionsTypes", ["subexpr", "@mut", [["get", "permissionsTypes", ["loc", [null, [85, 27], [85, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "canEditAll", ["subexpr", "@mut", [["get", "canEditAll", ["loc", [null, [86, 21], [86, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "canEditPermissions", ["subexpr", "@mut", [["get", "canEditPermissions", ["loc", [null, [87, 29], [87, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canEditPermissions", ["loc", [null, [88, 24], [88, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [88, 19], [88, 43]]], 0, 0]], ["loc", [null, [83, 10], [88, 45]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 6
            },
            "end": {
              "line": 90,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [48, 8], [52, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [54, 8], [63, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-customFieldsForm"], 2, null, ["loc", [null, [65, 8], [73, 25]]]], ["block", "dc-tab-panel", [], ["class", "at-permissionsForm"], 3, null, ["loc", [null, [75, 8], [89, 25]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 97,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n     ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element3, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element2, 2, 2);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element4, 1, 1);
        morphs[4] = dom.createMorphAt(element4, 3, 3);
        morphs[5] = dom.createMorphAt(element4, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIconSelector", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [15, 43], [15, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 4], [15, 57]]], 0, 0], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.resource_type.edit.title"], ["name", ["get", "model.resourceType.name", ["loc", [null, [20, 83], [20, 106]]], 0, 0, 0, 0]], ["loc", [null, [20, 26], [20, 107]]], 0, 0], "location", "at-manageResources-resourceTypeNew"], 1, null, ["loc", [null, [20, 4], [43, 22]]]], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [45, 24], [45, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 6], [45, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [47, 59], [47, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [47, 6], [90, 18]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [92, 48], [92, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "cancelAction", ["subexpr", "action", ["onCancel"], [], ["loc", [null, [92, 70], [92, 89]]], 0, 0], "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [92, 97], [92, 112]]], 0, 0]], ["loc", [null, [92, 5], [92, 114]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});