define('scientia-resourcebooker/pods/manage-resources/resource/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    unavailabilityUpdater: _ember['default'].inject.service(),

    queryParams: {
      selectedTab: { replace: false, refreshModel: false }
    },

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource.edit.page_title') + ' — ' + model.resource.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),

    model: function model(params, transition) {
      var resourceTypeId = transition.params['manage-resources.resource'].resourceTypeId;

      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: resourceTypeId, adminPermissionsOnly: true }),
        resource: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        eventPatterns: []
      });
    },

    afterModel: function afterModel(model) {

      if (this._redirectIfInvalidModel(model)) {
        return;
      }

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id'),
        resourceTypeName: model.resourceType.get('name'),
        resourceTypeId: model.resourceType.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {

        controller.initWeekdaySlotsArray();
        controller.initDateSlotsArray();

        controller.setProperties({
          selectedTab: 0,
          availabilityTab: 0,
          error: '',
          isSaving: false
        });
      }
    },

    _redirectIfInvalidModel: function _redirectIfInvalidModel(_ref) {
      var resourceType = _ref.resourceType;
      var resource = _ref.resource;

      if (resource.get('resourceTypeId') !== resourceType.get('id')) {
        this.transitionTo('manage-resources.resource-type.index');
        return false;
      }
      return true;
    },

    _updateUnavailability: function _updateUnavailability() {
      var controller = this.controllerFor(this.routeName);
      var pendingPatternSlots = controller.get('pendingPatternSlots');
      var pendingDateSlots = controller.get('pendingDateSlots');
      if (_ember['default'].isEmpty(pendingPatternSlots) && _ember['default'].isEmpty(pendingDateSlots)) return _ember['default'].RSVP.resolve();

      return this.get('unavailabilityUpdater').update({
        type: 'resource',
        id: controller.get('model.resource.id'),
        parentId: controller.get('model.resource.resourceTypeId'),
        patternSlots: pendingPatternSlots,
        specificDateSlots: pendingDateSlots });
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        this.store.peekAll('property').filterBy('resource', model.resource).invoke('rollbackAttributes');
        if (model.resource.get('hasDirtyAttributes')) model.resource.rollbackAttributes();
        model.resource.unloadRecord();
      },

      updateResource: function updateResource(model, transitionIndex) {
        var _this = this;

        var promises = [this._updateUnavailability(), model.save()];

        var controller = this.controllerFor(this.routeName);

        controller.set('isSaving', true);
        controller.set('error', '');

        _ember['default'].RSVP.all(promises).then(function () {
          _this.editSuccessFlash('resource');

          if (transitionIndex) {
            return _this.transitionTo('manage-resources.resource-type.show', model.get('resourceTypeId'));
          } else {

            _this.send('reload', {
              startDate: controller.get('startDate'),
              endDate: controller.get('endDate')
            });
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('resource');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate, userGroupId, isReloading) {
        var _this2 = this;

        var model = this.modelFor(this.routeName);
        this.send('loading');
        return this.get('resourceBusyTimesFetcher').fetch({
          type: 'resource',
          id: model.resource.get('id'),
          parentId: model.resource.get('resourceTypeId'),
          startDate: startDate,
          endDate: endDate,
          userGroupId: userGroupId
        }).then(function (events) {
          model.events.clear();
          if (isReloading) {
            _this2.controllerFor(_this2.routeName).set('pendingDateSlots', []);
          }
          _ember['default'].set(model, 'events', events.slice());
        })['catch'](function (e) {
          _this2.flashError(_this2.get('i18n').t('components.manage_resources.availability_form.unavailability_fetch_error'));
          LE.error({ location: 'fetchBusyTimes', error: e });
        })['finally'](function () {
          _this2.send('finished');
        });
      },

      fetchBusyPatterns: function fetchBusyPatterns(userGroupId, isReloading) {
        var _this3 = this;

        var model = this.modelFor(this.routeName);
        this.send('loading');

        return this.get('resourceBusyPatternsFetcher').fetch({
          type: 'resource',
          id: model.resource.get('id'),
          parentId: model.resource.get('resourceTypeId'),
          userGroupId: userGroupId
        }).then(function (events) {
          model.eventPatterns.clear();
          _ember['default'].set(model, 'eventPatterns', events.slice());
          if (isReloading) {
            _this3.controllerFor(_this3.routeName).set('pendingPatternSlots', []);
          }
        })['catch'](function (e) {
          _this3.flashError(_this3.get('i18n').t('components.manage_resources.availability_form.unavailability_fetch_error'));
          LE.error({ location: 'fetchBusyPatterns', error: e });
        })['finally'](function () {
          _this3.send('finished');
        });
      },

      reload: function reload(_ref2) {
        var startDate = _ref2.startDate;
        var endDate = _ref2.endDate;
        var userGroup = _ref2.userGroup;

        var model = this.modelFor(this.routeName);

        this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: model.resource.get('resourceTypeId'),
          id: model.resource.get('id'),
          data: { adminPermissionsOnly: true }
        });

        this.send('fetchBusyPatterns', userGroup, true);

        if (startDate && endDate) {
          this.send('fetchBusyTimes', startDate, endDate, userGroup, true);
        }
      }
    }
  });
});