define("scientia-resourcebooker/pods/components/manage-resources/notification-templates/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [7, 29], [7, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 24], [7, 60]]], 0, 0], false], [], ["loc", [null, [7, 16], [7, 67]]], 0, 0], "confirmTitle", ["subexpr", "t", [["subexpr", "concat-strings", ["components.manage_resources.notification_templates.", ["subexpr", "if", [["get", "useCustomTemplates", ["loc", [null, [8, 100], [8, 118]]], 0, 0, 0, 0], "toggle_off_title", "toggle_on_title"], [], ["loc", [null, [8, 96], [8, 156]]], 0, 0]], [], ["loc", [null, [8, 26], [8, 157]]], 0, 0]], [], ["loc", [null, [8, 23], [8, 158]]], 0, 0], "confirmMessage", ["subexpr", "t", [["subexpr", "concat-strings", ["components.manage_resources.notification_templates.", ["subexpr", "if", [["get", "useCustomTemplates", ["loc", [null, [9, 102], [9, 120]]], 0, 0, 0, 0], "toggle_off_message", "toggle_on_message"], [], ["loc", [null, [9, 98], [9, 162]]], 0, 0]], [], ["loc", [null, [9, 28], [9, 163]]], 0, 0]], [], ["loc", [null, [9, 25], [9, 164]]], 0, 0], "confirm", ["subexpr", "action", ["toggleUseCustomTemplatesConfirm"], [], ["loc", [null, [10, 18], [10, 60]]], 0, 0]], ["loc", [null, [5, 13], [10, 61]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [11, 36], [11, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 31], [11, 67]]], 0, 0], false], [], ["loc", [null, [11, 23], [11, 74]]], 0, 0]], ["loc", [null, [4, 11], [11, 75]]], 0, 0]], ["loc", [null, [2, 3], [12, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "copyFromSystemModalData", ["loc", [null, [21, 29], [21, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 24], [21, 53]]], 0, 0], null], [], ["loc", [null, [21, 16], [21, 59]]], 0, 0], "model", ["subexpr", "@mut", [["get", "copyFromSystemModalData", ["loc", [null, [22, 16], [22, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 13], [22, 40]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [23, 41], [23, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 31], [23, 46]]], 0, 0]], [], ["loc", [null, [23, 23], [23, 47]]], 0, 0]], ["loc", [null, [18, 11], [23, 48]]], 0, 0]], ["loc", [null, [16, 3], [24, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["location", "at-notificationTemplates--big"], ["loc", [null, [28, 2], [28, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 45,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "loading-icon", [], ["location", "at-notificationTemplates--big"], ["loc", [null, [44, 4], [44, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 4
                },
                "end": {
                  "line": 57,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/notification-templates/editor", [], ["selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [48, 23], [48, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "isSaving", ["subexpr", "@mut", [["get", "isSavingTemplate", ["loc", [null, [49, 15], [49, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [50, 18], [50, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [51, 15], [51, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystem", ["subexpr", "@mut", [["get", "isSystem", ["loc", [null, [52, 15], [52, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "copyFromSystem", ["subexpr", "action", ["copyFromSystem", true], [], ["loc", [null, [53, 21], [53, 51]]], 0, 0], "save", ["subexpr", "action", ["save", ["get", "selectedTemplate", ["loc", [null, [54, 26], [54, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 11], [54, 43]]], 0, 0]], ["loc", [null, [47, 6], [55, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 6
                },
                "end": {
                  "line": 59,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1, "class", "notificationsTemplates-noTemplateSelected--message");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.manage_resources.notification_templates.select_template"], [], ["loc", [null, [58, 69], [58, 143]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 2
              },
              "end": {
                "line": 60,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "selectedTemplate", ["loc", [null, [46, 10], [46, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [46, 4], [59, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "manage-resources/notification-templates/list", [], ["model", ["subexpr", "@mut", [["get", "templateSummary", ["loc", [null, [32, 8], [32, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedTemplate", ["subexpr", "@mut", [["get", "selectedTemplate", ["loc", [null, [33, 19], [33, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "clickedItemId", ["subexpr", "@mut", [["get", "clickedItemId", ["loc", [null, [34, 16], [34, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "useCustomTemplates", ["subexpr", "@mut", [["get", "useCustomTemplates", ["loc", [null, [35, 21], [35, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystem", ["subexpr", "@mut", [["get", "isSystem", ["loc", [null, [36, 11], [36, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [37, 11], [37, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "copyFromSystem", ["subexpr", "action", ["copyFromSystem"], [], ["loc", [null, [38, 17], [38, 42]]], 0, 0], "onSelect", ["subexpr", "action", ["onItemClick"], [], ["loc", [null, [39, 11], [39, 33]]], 0, 0], "toggleUseCustomTemplates", ["subexpr", "action", ["toggleUseCustomTemplates"], [], ["loc", [null, [40, 27], [40, 62]]], 0, 0]], ["loc", [null, [31, 2], [41, 4]]], 0, 0], ["block", "if", [["get", "isLoadingTemplate", ["loc", [null, [43, 8], [43, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [43, 2], [60, 7]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 62,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/notification-templates/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showCustomTemplatesToggleModal", ["loc", [null, [1, 6], [1, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 7]]]], ["block", "if", [["get", "copyFromSystemModalData", ["loc", [null, [15, 6], [15, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [25, 7]]]], ["block", "if", [["get", "isLoadingSummary", ["loc", [null, [27, 6], [27, 22]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [27, 0], [61, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});