define("scientia-resourcebooker/pods/manage-resources/booking-form/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteForm", ["loc", [null, [7, 25], [7, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 47]]], 0, 0], null], [], ["loc", [null, [7, 12], [7, 53]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.index.confirm_delete_form_title"], [], ["loc", [null, [8, 19], [8, 91]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.index.confirm_delete_form_message"], [], ["loc", [null, [9, 21], [9, 95]]], 0, 0], "confirm", ["subexpr", "action", ["deleteBookingFormConfirm"], [], ["loc", [null, [10, 14], [10, 49]]], 0, 0]], ["loc", [null, [5, 9], [10, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteForm", ["loc", [null, [11, 32], [11, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 27], [11, 54]]], 0, 0], null], [], ["loc", [null, [11, 19], [11, 60]]], 0, 0]], ["loc", [null, [4, 7], [11, 61]]], 0, 0]], ["loc", [null, [2, 1], [12, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDuplicateForm", ["loc", [null, [21, 25], [21, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 20], [21, 50]]], 0, 0], null], [], ["loc", [null, [21, 12], [21, 56]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.index.confirm_duplicate_form_title"], [], ["loc", [null, [22, 19], [22, 94]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.index.confirm_duplicate_form_message"], [], ["loc", [null, [23, 21], [23, 98]]], 0, 0], "confirm", ["subexpr", "action", ["duplicateBookingFormConfirm"], [], ["loc", [null, [24, 14], [24, 52]]], 0, 0]], ["loc", [null, [19, 9], [24, 53]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDuplicateForm", ["loc", [null, [25, 32], [25, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 27], [25, 57]]], 0, 0], null], [], ["loc", [null, [25, 19], [25, 63]]], 0, 0]], ["loc", [null, [18, 7], [25, 64]]], 0, 0]], ["loc", [null, [16, 1], [26, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 6
              },
              "end": {
                "line": 40,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "section-title-actions/item", [], ["icon", "icons/forms.svg", "text", ["subexpr", "t", ["pods.manage_resources.booking_form.index.create_booking_form"], [], ["loc", [null, [37, 15], [37, 81]]], 0, 0], "routeTarget", "manage-resources.booking-form.new"], ["loc", [null, [35, 10], [39, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 4
            },
            "end": {
              "line": 41,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [34, 6], [40, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-bookingFormsList");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(element1, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showConfirmDeleteForm", ["loc", [null, [1, 6], [1, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 7]]]], ["block", "if", [["get", "showConfirmDuplicateForm", ["loc", [null, [15, 6], [15, 30]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [27, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [30, 39], [30, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 0], [30, 53]]], 0, 0], ["block", "section-title", [], ["icon", "icons/forms.svg", "text", ["subexpr", "t", ["pods.manage_resources.booking_form.index.forms"], [], ["loc", [null, [33, 49], [33, 101]]], 0, 0], "location", "at-manageResourcesBookingFormsList"], 2, null, ["loc", [null, [33, 4], [41, 22]]]], ["inline", "manage-resources/booking-form-list", [], ["items", ["subexpr", "sort-by", ["name", ["get", "model", ["loc", [null, [44, 28], [44, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 12], [44, 34]]], 0, 0], "type", "booking-form", "hideShowAction", true, "deleteItemType", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteForm", ["loc", [null, [47, 34], [47, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 29], [47, 56]]], 0, 0]], [], ["loc", [null, [47, 21], [47, 57]]], 0, 0], "duplicateItemType", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDuplicateForm", ["loc", [null, [48, 37], [48, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 32], [48, 62]]], 0, 0]], [], ["loc", [null, [48, 24], [48, 63]]], 0, 0], "showItemPath", "manage-resources.booking-form.edit", "editItemPath", "manage-resources.booking-form.edit"], ["loc", [null, [43, 6], [51, 6]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});