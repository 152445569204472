define('scientia-resourcebooker/models/booking-request', ['exports', 'ember', 'ember-data', 'model-fragments/attributes'], function (exports, _ember, _emberData, _modelFragmentsAttributes) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    _id: attr('string'),
    name: attr('string'),
    owner: attr('string'),
    status: attr('string'),
    description: attr('string'),
    canEdit: attr('boolean'),
    canCancel: attr('boolean'),

    startDateTime: attr('date'),
    endDateTime: attr('date'),

    hasRecurrence: attr('boolean'),
    bookerName: attr('string'),
    bookerEmailAddress: attr('string'),
    reference: attr('string'),
    additionalEmailAddresses: attr(),
    recurrencePattern: (0, _modelFragmentsAttributes.fragment)('recurrence-pattern'),

    nameWithFallback: (function () {
      return this.get('name') || this.get('resources.firstObject.name');
    }).property('name', 'resources.firstObject.name'),

    resources: attr('raw'),
    resourceIdLanesViewVariant: attr('string'),

    occurrences: attr('raw'),
    occurrenceGroupId: attr('string'),
    bookingType: belongsTo('booking-type', { async: true }),
    bookingTypeId: attr('string'),
    eventId: attr('string'),
    historyCount: attr('number'),

    revisedBy: attr('string'),
    revisedByEmailAddress: attr('string'),
    bookingRequestFormValue: attr(),

    nameWithPrimaryResourceNames: (function () {
      var maxLength = 60;
      var resources = this.get('resources');
      var primaryResource = resources.findBy('isPrimary') || resources.get('firstObject');

      var resourceName = '';
      if (!_ember['default'].isEmpty(primaryResource)) {
        resourceName = primaryResource.get('name');
      }

      if (resourceName.length > 0) {
        if (resourceName.length > maxLength) {
          resourceName = resourceName.substr(0, maxLength - 1) + '…';
        }

        resourceName = ' (' + resourceName + ')';
      }

      return this.get('name') + resourceName;
    }).property('resources', 'eventId', 'id', 'resources.@each.isPrimary'),

    duration: (function () {
      var duration = moment(this.get('startDateTime')).diff(moment(this.get('endDateTime')));
      return moment.duration(duration).humanize();
    }).property('startDateTime', 'endDateTime')

  });
});