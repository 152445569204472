define('scientia-resourcebooker/services/app-insights', ['exports', 'ember'], function (exports, _ember) {
  /*
  ----EVENTS-----
  
  login
  logout
  language-change : {language}
  show-availability
  
  booking-created : {resourceId, resourceName, bookingTypeId, bookingTypeName}
  booking-edited : {resourceId, resourceName, bookingTypeId, bookingTypeName, bookingId, occurrenceId, isOwner, type, from}
  booking-cancelled: {isOwner, type, bookingId, bookingName, from}
  search-global: {query, type}
  search-resource: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, query}
  load-more
  notification-accept
  notification-request
  notification-more-info
  
  3.0 added:
  bulk-import-template-download {type : custom-fields/resources, file: with-mapping/no-mapping/mapping-file}
  bulk-export {all: true, ids: []}
  bulk-import: {step: preview/confirm, type, request, response}
  report-dashboard-view: {reportId, reportName}
  report-audit-generate: {fromDate, toDate, useBookingDates, isAdmin, count}
  template-help-click: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, resourceGroupName }
  global-help-click: {path, locale, hasHelpData}
  global-help-topic-click': {path, topic, locale, hasHelpDataInCurrentLocale}
  resource-summary: {resourceTypeId, resourceId}
  
  booking-created: added -> {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-edited: added => {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-print: {bookingTypeId}
  booking-search: {query, count, currentPage, totalPages}
  my-bookings-print, {isSearchResults}
  
  resource-filter: {bookingType, bookingTypeId, name, value, type}
  resource-found-filters: {resourceName, bookingTypeId, bookingTypeName, filters, duration, query, availableNow, spanMultipleDays,firstDay, lastDay,fromTime, toTime}
  
  3.1:
  permissions-summary {type: resource/user-group/self/search, resourceTypeId, resourceId, isAdmin, claimType, includeEveryoneGroup}
  permissions-summary-bulk-clear {totalResults, successfulResults}
  permissions-summary-link {type: resource/usergroup, isAdmin}
  
  click-subscribe-{type}
  click-subscribe-regenerate-token
  click-download-calendar
  click-download-calendar-copy-button-success
  click-download-calendar-copy-button-fail
  
  report-bookings-generate {fromDate, toDate, isAdmin, count}
  report-bookings-refresh {isAdmin}
  report-bookings-export {isAdmin}
  report-bookings-print {isAdmin}
  report-audit-generate: {fromDate, toDate, useBookingDates, isAdmin, count} // updated from 3.0
  report-bookings-toggle-active-bookings {value, isAdmin}
  
  calendar-drag, {fromTime, toTime, isAdmin, isEditing}
  
  concurrency-view-mode {mode}
  ******
  
  booking-type-notification-templates-toogle {value, bookingTypeId}
  booking-type-notification-templates-copy-from-system {allTemplates: boolean, bookingTypeId, notificationType, reciepientType}
  booking-type-notification-template-save {bookingTypeId, recipientType, notificationType sendEmail, isSystem}
  concurrency-toggle-lanes-mode {value}
  concurrency-toggle-template-unavailability {id, name, resources}
  concurrency-view-mode {value}
  ----------------------------------------
   */
  exports['default'] = _ember['default'].Service.extend({
    systemSettings: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    trackEvent: function trackEvent(event, data) {

      if (!data) data = {};

      try {
        data.isAdmin = this.get('currentUser').isAdmin();
      } catch (error) {
        //no-op
      }

      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackEvent(event, data);
    },

    trackPageView: function trackPageView(name) {
      var properties = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackPageView(name, null, properties);
    },

    trackBookingTypeLoadMore: function trackBookingTypeLoadMore(bookingType, resourceGroup) {
      this.trackEvent('load-more', {
        bookingTypeName: bookingType.get('name'),
        bookingTypeId: bookingType.get('id'),
        isResourceGroup: !!resourceGroup,
        resourceGroupId: resourceGroup ? _ember['default'].get(resourceGroup, 'id') : '',
        resourceGroupName: resourceGroup ? _ember['default'].get(resourceGroup, 'name') : ''
      });
    },

    trackBookingTypeHelpTriggerClick: function trackBookingTypeHelpTriggerClick(bookingType, resourceGroup) {
      this.trackEvent('template-help-click', {
        bookingTypeName: bookingType.get('name'),
        bookingTypeId: bookingType.get('id'),
        isResourceGroup: !!resourceGroup,
        resourceGroupId: resourceGroup ? _ember['default'].get(resourceGroup, 'id') : '',
        resourceGroupName: resourceGroup ? _ember['default'].get(resourceGroup, 'name') : ''
      });
    },

    trackBookingTypeFilterApplied: function trackBookingTypeFilterApplied(filterData, bookingType) {
      // Don't log if level 1 or lower
      if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) <= 1) {
        return;
      }
      filterData.bookingType = _ember['default'].get(bookingType, 'name');
      filterData.bookingTypeId = _ember['default'].get(bookingType, 'id');
      this.trackEvent('resource-filter', filterData);
    },

    trackBookingTypeNotificationTemplateUpdate: function trackBookingTypeNotificationTemplateUpdate(template) {
      var logData = {
        bookingTypeId: template.get('bookingTypeIdentity'),
        recipientType: template.get('recipientType'),
        notificationType: template.get('name'),
        sendEmail: template.get('sendEmail'),
        isSystem: template.get('bookingTypeIdentity') === '00000000-0000-0000-0000-000000000000'
      };

      this.trackEvent('booking-type-notification-templates-save', logData);
    },

    trackConcurrencyToggleTemplateUnavailability: function trackConcurrencyToggleTemplateUnavailability(concurrencyObject, value) {
      var logData = {
        id: concurrencyObject.get('id'),
        name: concurrencyObject.get('name'),
        resources: concurrencyObject.get('resources.length'),
        value: value
      };
      this.trackEvent('concurrency-toggle-template-unavailability', logData);
    },

    trackConcurrencyToggleConcurrencyUnavailability: function trackConcurrencyToggleConcurrencyUnavailability(concurrencyObject, value) {
      var logData = {
        id: concurrencyObject.get('id'),
        name: concurrencyObject.get('name'),
        resources: concurrencyObject.get('resources.length'),
        value: value
      };
      this.trackEvent('concurrency-toggle-concurrency-unavailability', logData);
    }
  });
});