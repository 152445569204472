define("scientia-resourcebooker/pods/components/manage-resources/claims-form/claim-value/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/claims-form/claim-value/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--select");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        return morphs;
      },
      statements: [["inline", "rb-select", [], ["disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [3, 13], [3, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "content", ["subexpr", "@mut", [["get", "claimTypeValues", ["loc", [null, [4, 12], [4, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "claim.claimType", ["loc", [null, [5, 10], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "value", "optionLabelPath", "label", "class", "at-claimsForm"], ["loc", [null, [2, 2], [9, 4]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "claim.claimValue", ["loc", [null, [12, 14], [12, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [12, 40], [12, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input manageResources-itemForm-form-input"], ["loc", [null, [12, 0], [12, 105]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});